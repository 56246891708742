import React, { useEffect, useRef } from 'react';
import {
  ApolloProvider as ApolloBaseProvider,
  NormalizedCacheObject,
} from '@apollo/client';
import { ApolloProviderProps } from '@apollo/client/react/context';

/**
 * Wrapper around the ApolloProvider that prevents overfetching during SSR by disabling network
 * requests during the react app rendering via `getDataFromTree`. This replaces the need for the
 * `ssrForceFetchDelay` config on ApolloClient.
 *
 * @see https://github.com/apollographql/apollo-client/issues/4814 - disableNetworkFetches hack
 * @see https://stackoverflow.com/a/56818036 - relevant hooks
 */
export default function ApolloProvider({
  client,
  ...props
}: ApolloProviderProps<NormalizedCacheObject>) {
  useComponentWillMount(() => {
    // eslint-disable-next-line no-param-reassign
    client.disableNetworkFetches = true;
  });
  useComponentDidMount(() => {
    // eslint-disable-next-line no-param-reassign
    client.disableNetworkFetches = false;
  });
  return <ApolloBaseProvider client={client} {...props} />;
}

const useComponentWillMount = (cb: Function) => {
  const willMount = useRef(true);
  if (willMount.current) cb();
  willMount.current = false;
};

const useComponentDidMount = (cb: React.EffectCallback) => useEffect(cb, []);
