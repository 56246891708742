import getConfig from 'next/config';
import {
  ClipEventRealtime,
  Event as MixpanelEvent,
  EventName,
} from './eventTrackingServiceTypes';

import { camelCaseToCapitalWords } from '../util/stringFormatting';
import imperativePromise from '../util/imperativePromise';

export type { CuesApplied, ProjectType } from './eventTrackingServiceTypes';
export {
  ApiKeyRequestStatus,
  ClipEventRealtime,
  EventName,
  ProjectSharedEventType,
  ReplacementsDictionaryLocation,
  TeamAccountManagementSource,
} from './eventTrackingServiceTypes';

declare global {
  interface Window {
    mixpanel?: Mixpanel;
  }

  interface Mixpanel {
    __loaded: boolean;
    // https://github.com/DefinitelyTyped/DefinitelyTyped/blob/09247eec68d126289bab4f7129ee9eab3c467388/types/mixpanel/index.d.ts
    init(
      token: string,
      config?: Mixpanel.Config,
      libraryName?: string
    ): Mixpanel;
    identify(uniqueId?: string): void;
    people: Mixpanel.People;
    register(properties: { [index: string]: unknown }, days?: number): void;
    reset(): void;
    set_config(config: Mixpanel.Config): void;
    // https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpaneltrack
    track(
      eventName: string,
      properties?: { [index: string]: unknown },
      options?: {
        transport?: 'xhr' | 'sendBeacon';
        send_immediately?: boolean;
      },
      callback?: () => void
    ): void;
  }

  namespace Mixpanel {
    interface Config {
      // https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelset_config
      api_transport?: 'XHR' | 'sendBeacon';
    }

    interface People {
      // https://github.com/DefinitelyTyped/DefinitelyTyped/blob/09247eec68d126289bab4f7129ee9eab3c467388/types/mixpanel/index.d.ts
      set(prop: string, value: unknown, callback?: () => void): void;
      set(keys: { [index: string]: unknown }, callback?: () => void): void;
    }
  }
}

class EventTrackingService {
  constructor() {
    this.readyPromise = imperativePromise();
  }

  public static EVENTS = {
    PROJECT_DOWNLOAD: 'Project Download',
    TUTORIAL_VIEW: 'Tutorial View',
    CLIP_REORDER: 'Clip Re-order',
    CLIP_RENDER: 'Clip Render',
    CLIP_MOVED_PROJECT: 'Clip Moved Project',
    CLIP_REALTIME: 'Clip Realtime',
    CLIP_RENAMED: 'Clip Re-named',
    CLIP_COMBINED: 'Clip Combined',
    CLIP_SEARCH: 'Clip Search',
    PHONETIC_CREATION: 'Phonetic Creation',
    PHONETIC_PREVIEW_RENDER: 'Phonetic Preview Render',
    PHONETIC_IMPORT: 'Phonetic Library Import',
    PHONETIC_EXPORT: 'Phonetic Library Export',
  };

  /**
   * MixPanel library instance
   */
  private mixpanel?: Mixpanel;

  /**
   * User identity for event context
   */
  public identity?: string;

  /**
   * List of super properties that are sent along with all events
   */
  private superProperties: { [key: string]: any } = {
    // 'Subscription Tier': undefined,
    // 'Clips Generated': undefined,
  };

  private readyPromise: ReturnType<typeof imperativePromise>;

  /**
   * Initialization method (MixPanel library)
   */
  public init() {
    return new Promise<void>(resolve => {
      waitForMixpanelToLoad()
        .then(() => {
          // NOTE: this is mostly useful for development hot-reloading to prevent attempts at
          // re-initializing the mixpanel instance (global window reference)
          if (this.mixpanel || window?.mixpanel?.__loaded) {
            // Prevent re-initialization
            this.mixpanel = window.mixpanel;
            resolve();
            return;
          }
          const { publicRuntimeConfig } = getConfig();
          const {
            MIXPANEL_CUSTOM_LIB_URL,
            MIXPANEL_PROJECT_ACCESS_TOKEN,
            IS_PRODUCTION,
          } = publicRuntimeConfig;
          window?.mixpanel?.init(MIXPANEL_PROJECT_ACCESS_TOKEN, {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore See official docs, @types/mixpanel is likely out of date
            // override browser do not track when in development context
            ignore_dnt: !IS_PRODUCTION,
            batch_requests: true,
            loaded: (mixpanel: Mixpanel) => {
              this.mixpanel = mixpanel;
              resolve();
            },
            debug: !IS_PRODUCTION,
            api_host: MIXPANEL_CUSTOM_LIB_URL,
          });

          // Unlike the other instances where we register super properties, we don't need to track
          // the client version change in a useEffect. Because the client version change only takes
          // effect after a page reload which causes this class to be re-instantiated.
          this.trackClientVersion();
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.warn(`Failed to load mixpanel`, error);
        });
    });
  }

  /**
   * Cleanup method, useful for switching user context or logout. Note that the mixpanel
   * instance is defined globally via browser window, so the destroy method will help in the
   * event that we re-instantiate another EventTrackingService instance.
   */
  public destroy() {
    if (this.mixpanel) this.mixpanel.reset();
    this.identity = undefined;
  }

  /**
   * Initialization of the user context (after library initialization)
   */
  public setUserIdentity(userId: string) {
    if (!this.mixpanel)
      throw new Error(`Unable to set user identity, service not initialized`);
    this.identity = userId;
    this.mixpanel.identify(userId);
    // The assumption here is that one the identity is set, we have all the information needed
    // to start tracking
    this.readyPromise.resolve();
  }

  /**
   * @description Track the user's profile information.
   *
   * @property {Object} inputs
   * @property {string} inputs.email
   * @property {string} [inputs.signUpDate] ISO timestamp format (e.g. "2020-01-02T21:07:03Z")
   * @property {string} [inputs.approvalDate] ISO timestamp format (e.g. "2020-01-02T21:07:03Z")
   * @property {string} [inputs.name]
   * @property {string} [inputs.firstName]
   * @property {string} [inputs.lastName]
   * @property {string} [inputs.avatar] URL format
   * @property {number} [inputs.clipsGenerated]
   */
  public trackUserProfile(inputs: {
    email: string;
    signUpDate?: string;
    approvalDate?: string;
    name?: string;
    firstName?: string;
    lastName?: string;
    avatar?: string;
    clipsGenerated?: number;
    teamId?: string;
    teamRole?: string;
  }) {
    if (!this.mixpanel || !this.identity) return;
    // For reserved profile properties, see:
    // https://help.mixpanel.com/hc/en-us/articles/115004708186-Profile-Properties#reserved-properties-for-user-profiles
    this.mixpanel.people.set({
      USER_ID: this.identity,
      $email: inputs.email,
      $avatar: inputs.avatar,
      $name: inputs.name,
      $firstName: inputs.firstName,
      $lastName: inputs.lastName,
      // Custom attributes
      'Sign up date': inputs.signUpDate,
      'Approval date': inputs.approvalDate,
      'Clips Generated': inputs.clipsGenerated,
      'Team ID': inputs.teamId,
      'Team Role': inputs.teamRole,
    });
    this.superProperties = {
      ...this.superProperties,
      'Clips Generated': inputs.clipsGenerated,
    };
  }

  /**
   * @description Track the number of clips a user has generated
   */
  public trackClipsGenerated(inputs: { clipsCount: number }) {
    this.readyPromise.then(() => {
      this.superProperties = {
        ...this.superProperties,
        'Clips Generated': inputs.clipsCount,
      };
      // Register as a super property (provides the clips count to all events)
      this.mixpanel!.register(this.superProperties);
      this.mixpanel!.people.set('Clips Generated', inputs.clipsCount);
    });
  }

  /**
   * @description Track the user's subscription tier
   */
  public trackSubscriptionTier(inputs: { tier: string }) {
    this.readyPromise.then(() => {
      this.superProperties = {
        ...this.superProperties,
        'Subscription Tier': inputs.tier,
      };
      // Register as a super property (provides the subscription tier to all events)
      this.mixpanel!.register(this.superProperties);
    });
  }

  /**
   * @description Track the user's client version
   */
  public trackClientVersion() {
    const { TAG_NAME, SHORT_SHA } = getConfig().publicRuntimeConfig;

    const version = TAG_NAME || SHORT_SHA || 'N/A';

    this.readyPromise.then(() => {
      this.superProperties = {
        ...this.superProperties,
        'Client Version': version,
      };
      // Register as a super property (provides the client version to all events)
      this.mixpanel!.register(this.superProperties);
    });
  }

  /**
   * @description Track the number of projects a user has created
   */
  public trackProjectsCount(inputs: { projectsCount: number }) {
    this.readyPromise.then(() => {
      this.mixpanel!.people.set('Projects Created', inputs.projectsCount);
    });
  }

  /**
   * @description Track when a user views a tutorial within the studio
   */
  public trackTutorialView(inputs: { name: string; category: string }) {
    this.readyPromise.then(() => {
      this.mixpanel!.track(EventTrackingService.EVENTS.TUTORIAL_VIEW, {
        'Tutorial Name': inputs.name,
        'Tutorial Category': inputs.category,
      });
    });
  }

  /**
   * @description Track when a user re-orders clips within a project
   */
  public trackClipReorder() {
    this.readyPromise.then(() => {
      this.mixpanel!.track(EventTrackingService.EVENTS.CLIP_REORDER);
    });
  }

  /**
   * @description Track when a user combines audio clips. Note that this includes both succesfull
   * and failed responses
   */
  public trackClipCombined(inputs: {
    success: boolean;
    numberOfClipsCombined: number;
    msGapDuration: number;
    error?: string;
  }) {
    this.readyPromise.then(() => {
      this.mixpanel!.track(EventTrackingService.EVENTS.CLIP_COMBINED, {
        'Clips Combined': inputs.numberOfClipsCombined,
        'Spacing Between Clips': inputs.msGapDuration,
        Success: inputs.success,
        Error: inputs.error,
      });
    });
  }

  /**
   * @description Track when a user moves a clip to another project
   */
  public trackClipMovedProject(inputs: { clipsCount: number }) {
    this.readyPromise.then(() => {
      this.mixpanel!.track(EventTrackingService.EVENTS.CLIP_MOVED_PROJECT, {
        'Clips Count': inputs.clipsCount,
      });
    });
  }

  public trackClipRealtime(inputs: {
    clipId: string;
    previousProjectId?: string;
    projectId: string;
    actorId: string;
    actorVariantId: string;
    actorVariantType: string;
    actorName: string;
    eventType: ClipEventRealtime;
    textLength: number;
    ttsVersion: string;
  }) {
    this.readyPromise.then(() => {
      this.mixpanel!.track(EventTrackingService.EVENTS.CLIP_REALTIME, {
        'Clip Id': inputs.clipId,
        'Event Type': inputs.eventType,
        'Former Project Id': inputs.previousProjectId,
        'Project Id': inputs.projectId,
        'Actor Id': inputs.actorId,
        'Actor Variant Id': inputs.actorVariantId,
        'Actor Variant Type': inputs.actorVariantType,
        'Actor Name': inputs.actorName,
        'Text Length': inputs.textLength,
        'TTS Version': inputs.ttsVersion,
      });
    });
  }

  /**
   * @description Track when a user re-names a clip
   */
  public trackClipRenamed(inputs: { clipName: string }) {
    this.readyPromise.then(() => {
      this.mixpanel!.track(EventTrackingService.EVENTS.CLIP_RENAMED, {
        'Clip Name': inputs.clipName,
      });
    });
  }

  /**
   * @description Track when a user re-names a clip
   */
  public trackClipSearch(inputs: { projectId: string }) {
    this.readyPromise.then(() => {
      this.mixpanel!.track(EventTrackingService.EVENTS.CLIP_SEARCH, inputs);
    });
  }

  /**
   * @description Traack the height that user sets for the editor after resizing
   */
  public trackEditorResized(inputs: { projectId: string; height: number }) {
    this.readyPromise.then(() => {
      this.mixpanel!.track(EventName.EditorResized, inputs);
    });
  }

  /**
   * @description Track when a user creates a phonetic replacement
   */
  public trackPhoneticLibraryCreation(inputs: {
    path: string;
    libraryType: 'team' | 'personal';
  }) {
    this.readyPromise.then(() => {
      this.mixpanel!.track(
        EventTrackingService.EVENTS.PHONETIC_CREATION,
        inputs
      );
    });
  }

  /**
   * @description Track when a user renders a phonetic replacement preview
   */
  public trackPhoneticLibraryPreviewRender(inputs: {
    path: string;
    // these fields are optional as they are only available in project detail
    avatarId?: number;
    avatarVariantId?: number;
    version?: string;
    location?: string;
  }) {
    this.readyPromise.then(() => {
      this.mixpanel!.track(
        EventTrackingService.EVENTS.PHONETIC_PREVIEW_RENDER,
        inputs
      );
    });
  }

  /**
   * @description Track when a user imports a phonetic library
   */
  public trackPhoneticLibraryImport(inputs: { libraryType: string }) {
    this.readyPromise.then(() => {
      this.mixpanel!.track(EventTrackingService.EVENTS.PHONETIC_IMPORT, {
        'Import Behavior': inputs.libraryType,
      });
    });
  }

  /**
   * @description Track when a user exports their phonetic library
   */
  public trackPhoneticLibraryExport(inputs: { libraryType: string }) {
    this.readyPromise.then(() => {
      this.mixpanel!.track(EventTrackingService.EVENTS.PHONETIC_EXPORT, inputs);
    });
  }

  /**
   * Track mixpanel events. Add to the `Events` union type to track more events.
   *
   * @param Event - see Event type in this file to see what events are available
   */
  public track(
    { type, data }: MixpanelEvent,
    options?: Parameters<Mixpanel['track']>[2],
    callback?: Parameters<Mixpanel['track']>[3]
  ) {
    this.readyPromise.then(() => {
      this.mixpanel!.track(
        type,
        typeof data === 'object' ? unCamelizeObject(data) : undefined,
        options,
        callback
      );
    });
  }

  /**
   * Dynamically change mixpanel configuration
   *
   * @see https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelset_config
   */
  public setConfig(config: Partial<Mixpanel.Config>) {
    this.readyPromise.then(() => {
      this.mixpanel!.set_config(config);
    });
  }
}

/**
 * Takes an object with camel-cased keys and returns a new object where the keys are
 * the capitalized words in the camel-case strings.
 *
 * @param object {Object} - Object with camelcased keys.
 * @example
 * const capitalized = unCamelizeObject({ camelCasedKey: 'dataForKey' });
 * capitalized === {
 *   'Camel Cased Key': 'dataForKey'
 * };
 */
const unCamelizeObject = (obj: { [key: string]: unknown }) =>
  Object.keys(obj).reduce((output, key) => {
    // eslint-disable-next-line no-param-reassign
    output[camelCaseToCapitalWords(key)] = obj[key];
    return output;
  }, {} as { [key: string]: unknown });

const waitForMixpanelToLoad = async (
  inputs: { retryCount: number; retryInterval: number } = {
    retryCount: 16,
    retryInterval: 250,
  }
): Promise<void> => {
  return new Promise((resolve, reject) => {
    let retries = inputs.retryCount;
    const intervalId = setInterval(() => {
      if (typeof window.mixpanel !== 'undefined') {
        clearInterval(intervalId);
        resolve();
        // eslint-disable-next-line no-cond-assign
      } else if ((retries -= 1) <= 0) {
        clearInterval(intervalId);
        reject(new Error(`Hit maximum retries for window.mixpanel to load`));
      }
    }, inputs.retryInterval);
  });
};

export default new EventTrackingService();
