// NOTE: Redux Toolkit allows us to assign values directly to the state in the reducers.
/* eslint-disable no-param-reassign */
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RemirrorJSON } from 'remirror';
import ProjectEditorStorage from '../context/editor/ProjectEditorStorage';
import { generateState, performUndoOrRedoSideEffects } from './editorUtils';
import { getCoreEditorRef } from '../context/editorV2/CoreEditorContext';
import { AppState } from '.';
import { resetControls } from './CuesSlice';

export enum EditMode {
  SCRIPT = 'script',
  CUES = 'cues',
}

export type EditorError = {
  message: string;
};

export type Script = {
  text: string;
  textFormat: string;
  json: RemirrorJSON | null;
};

type FinalScript = {
  charactersUsed: number;
  sentencesUsed: number;
  paragraphsUsed: number;
  plainTextArray: Array<string>;
  scriptArrayToRender: Array<string>;
};

export type EditorMeta = {
  canUndo: boolean;
  canRedo: boolean;
};

export type EditorState = {
  projectId: string | null;
  editMode: EditMode;
  renderMode: 'paragraph' | 'sentence' | 'all';
  error?: EditorError;
  script: Script;
  finalScript: FinalScript;
  characterLimit: number;
  shouldBeSingleTakeOnly: boolean;
  actorId?: string;
  variantId?: string;
  ttsVersion?: string;
  meta: EditorMeta;
};

export const initialState: EditorState = {
  projectId: null,
  editMode: EditMode.SCRIPT,
  renderMode: 'all',
  shouldBeSingleTakeOnly: false,
  characterLimit: 5000,
  error: undefined,
  script: {
    text: '',
    textFormat: '',
    json: null,
  },
  finalScript: {
    charactersUsed: 0,
    sentencesUsed: 0,
    paragraphsUsed: 0,
    plainTextArray: [''],
    scriptArrayToRender: [''],
  },
  actorId: '',
  variantId: '',
  ttsVersion: '',
  meta: {
    canUndo: false,
    canRedo: false,
  },
};

const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<Partial<EditorState>>) => {
      const storedScript = ProjectEditorStorage.getEditorScriptForProject(
        action.payload.projectId || ''
      );
      let updated = initialState;
      if (storedScript) {
        const script = JSON.parse(storedScript);
        updated = generateState(state, {
          renderMode: initialState.renderMode,
          ...script,
        });
      }

      return {
        ...updated,
        ...action.payload,
      };
    },
    setShouldBeSingleTakeOnly: (
      state,
      action: PayloadAction<EditorState['shouldBeSingleTakeOnly']>
    ) => {
      const shouldBeSingleTakeOnly = action.payload;
      return generateState(
        { ...state, shouldBeSingleTakeOnly },
        {
          renderMode: state.renderMode,
          ...state.script,
        }
      );
    },
    setEditMode: (state, action: PayloadAction<EditorState['editMode']>) => {
      return {
        ...state,
        editMode: action.payload,
      };
    },
    setDefaultActorFromVoicesPage: (
      state,
      action: PayloadAction<{
        actorId?: string;
        variantId?: string;
        ttsVersion?: string;
      }>
    ) => {
      return {
        ...state,
        actorId: action.payload.actorId,
        variantId: action.payload.variantId,
        ttsVersion: action.payload.ttsVersion,
      };
    },
    setRenderMode: (
      state,
      action: PayloadAction<EditorState['renderMode']>
    ) => {
      const renderMode = action.payload;
      return generateState(state, {
        renderMode,
        ...state.script,
      });
    },
    setScript: (
      state,
      action: PayloadAction<{
        text: string;
        textFormat: string;
        json: RemirrorJSON;
      }>
    ) => {
      const { text, textFormat, json } = action.payload;

      const storage = new ProjectEditorStorage(state.projectId || '');
      storage.setEditorScriptValues({ text, textFormat, json });

      return generateState(state, {
        renderMode: state.renderMode,
        ...action.payload,
      });
    },
    updateMeta: (state, action: PayloadAction<EditorMeta>) => {
      return {
        ...state,
        meta: action.payload,
      };
    },
  },
});

/**
 * loadFromJson
 * This action will update the editor with the JSON provided
 */
export const loadFromJson = createAsyncThunk<
  void,
  {
    json: RemirrorJSON;
  },
  { state: AppState }
>('editor/loadFromJson', ({ json }, { dispatch }) => {
  const coreEditorRef = getCoreEditorRef();
  if (!json) return;

  const textFromJson = coreEditorRef?.helpers.getText({ json });
  if (!textFromJson) return;

  coreEditorRef?.helpers.loadFromJson(json);
  dispatch(
    editorSlice.actions.setScript({ text: textFromJson, json, textFormat: '' })
  );
});

/**
 * RepopulateEditor
 * This action will either do two procedures:
 * 1. if textFormat is provided, set the content in the editor to the textFormat (script mode)
 * 2. if json is provided, creates state from the given json and the derived text from the json,
 *      and resets the cue controls
 */
export const repopulate = createAsyncThunk<
  void,
  { textFormat?: string; json?: RemirrorJSON },
  { state: AppState }
>('editor/repopulate', ({ textFormat, json }, { dispatch }) => {
  const coreEditorRef = getCoreEditorRef();

  if (textFormat) {
    coreEditorRef?.setContent(textFormat);
  } else if (json) {
    dispatch(loadFromJson({ json }));
    dispatch(resetControls(null));
  }
});

/**
 * Undo
 * This action does an undo command on the core editor,
 * and then updates the cue options to reflect the state of the active cue,
 * either by reseting the cue options or by setting the active cue.
 * Removes any active selections to prevent a collosion state where
 * there could be two selections at the same time.
 */
export const undo = createAsyncThunk<void, any, { state: AppState }>(
  'editor/undo',
  (_, { dispatch, getState }) => {
    const coreEditorRef = getCoreEditorRef();

    coreEditorRef?.commands.undo();

    performUndoOrRedoSideEffects({ dispatch, getState, coreEditorRef });
  }
);

/**
 * Redo
 * This action does a redo command on the core editor,
 * and then updates the cue options to reflect the state of the active cue,
 * either by reseting the cue options or by setting the active cue.
 * Removes any active selections to prevent a collosion state where
 * there could be two selections at the same time.
 */
export const redo = createAsyncThunk<void, any, { state: AppState }>(
  'editor/redo',
  (_, { dispatch, getState }) => {
    const coreEditorRef = getCoreEditorRef();

    coreEditorRef?.commands.redo();

    performUndoOrRedoSideEffects({ dispatch, getState, coreEditorRef });
  }
);

/**
 * setScript
 * Expanded action to set the script in the editor by also updating the meta such that
 * we can know what actions are available to the user.
 */
export const setScript = createAsyncThunk<void, any, { state: AppState }>(
  'editor/setScript',
  (payload, { dispatch }) => {
    dispatch(editorSlice.actions.setScript(payload));

    const coreEditorRef = getCoreEditorRef();

    const meta = coreEditorRef?.helpers.getMeta() || initialState.meta;

    dispatch(editorSlice.actions.updateMeta(meta));
  }
);

/**
 * setEditMode
 * Expanded action to setEditMode such that we are also resetting the history of the core editor
 * such that we don't allow the user to undo/redo to a bad state given the tokenization of the text.
 */
export const setEditMode = createAsyncThunk<void, any, { state: AppState }>(
  'editor/setEditMode',
  (payload: EditMode, { dispatch }) => {
    dispatch(editorSlice.actions.setEditMode(payload));

    const coreEditorRef = getCoreEditorRef();
    coreEditorRef?.helpers.resetHistory();

    const meta = coreEditorRef?.helpers.getMeta() || initialState.meta;
    dispatch(editorSlice.actions.updateMeta(meta));
  }
);

export const {
  set: setEditorState,
  setDefaultActorFromVoicesPage,
  setRenderMode,
  setShouldBeSingleTakeOnly,
} = editorSlice.actions;

export default editorSlice;
