import React, { Component } from 'react';
import Icon from 'components/Icon';
import IconButton from './IconButton/IconButton';
import theme from '../theme';
import { paletteV2 } from '../theme/palette';

const DELAY_FADING = 4000;
const DELAY_CLOSE = 4000;

/**
 * Component that appears in the bottom left corner with feedback for the user on an action.
 */
class Feedback extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFading: false,
      isClosed: false, // `true` if some action has been taken like `callToAction` or `onClose`.
      onClose: () => {},
      callToAction: {
        onClick: () => {},
        name: '',
      },
      title: undefined,
      message: '',
      isError: undefined,
      iconType: undefined,
    };

    this.timeoutToFade = undefined;
    this.timeoutToClose = undefined;

    this.handleFeedback = this.handleFeedback.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCallToAction = this.handleCallToAction.bind(this);
    this.stopTimer = this.stopTimer.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.resetState = this.resetState.bind(this);
  }

  componentDidUpdate() {
    // On refresh, ensure the current feedback is handled.
    window.addEventListener('beforeunload', this.handleClose);
  }

  componentWillUnmount() {
    this.handleClose();
    window.removeEventListener('beforeunload', this.handleClose);
  }

  /**
   * Update state to new feedback.
   *
   * @param {*}
   */
  handleFeedback({
    isError,
    message,
    title,
    onClose = () => {},
    callToAction = { onClick: () => {}, name: '' },
    iconType,
  } = {}) {
    this.handleClose();
    this.resetState();
    this.startTimer();
    this.setState({ isError, message, title, onClose, callToAction, iconType });
  }

  /**
   * Close the current state.
   */
  handleClose() {
    const { isClosed, onClose } = this.state;
    if (!isClosed) {
      this.stopTimer();
      this.setState({ isClosed: true });
      onClose();
    }
  }

  handleCallToAction() {
    const {
      isClosed,
      callToAction: { onClick },
    } = this.state;
    if (!isClosed) {
      this.stopTimer();
      this.setState({ isClosed: true });
      onClick(); // TODO: Consider adding some loading indicator while this executes.
    }
  }

  stopTimer() {
    clearTimeout(this.timeoutToFade);
    clearTimeout(this.timeoutToClose);
    this.timeoutToFade = undefined;
    this.timeoutToClose = undefined;
  }

  startTimer() {
    if (
      typeof this.timeoutToFade !== 'undefined' ||
      typeof this.timeoutToClose !== 'undefined'
    ) {
      console.error('Timers already set.');
    }

    this.timeoutToFade = setTimeout(() => {
      this.setState({ isFading: true });
      this.timeoutToClose = setTimeout(this.handleClose, DELAY_CLOSE);
    }, DELAY_FADING);
  }

  resetState() {
    this.stopTimer();
    this.setState({
      isFading: false,
      isClosed: false,
    });
  }

  render() {
    const {
      message,
      isFading,
      isClosed,
      isError,
      callToAction: { name: callToActionName },
      iconType,
      title,
    } = this.state;

    const opacity = isFading ? 0 : 1;
    const transition = isFading ? DELAY_CLOSE : 0;
    const isSuccessIconDisplayed = iconType === 'success';
    const isErrorIconDisplayed = iconType === 'error';
    let display = isClosed ? 'none' : 'flex';
    if (isSuccessIconDisplayed || isErrorIconDisplayed) {
      display = isClosed ? 'none' : 'grid';
    }

    return message.length > 0 ? (
      <div
        id="feedback"
        role="alert"
        aria-live="assertive"
        aria-labelledby="message"
        aria-hidden="false"
        aria-atomic="true"
        style={{
          transition: `opacity ${transition}ms ease-in`,
          opacity,
          display,
          left: 24,
          transform: 'none',
          backgroundColor: isError && theme.palette.error.dark,
          gridAutoFlow: 'column',
        }}
        onMouseLeave={this.startTimer}
        onMouseEnter={this.resetState}
        onBlur={this.startTimer}
        onFocus={this.resetState}
      >
        {isSuccessIconDisplayed && (
          <div
            style={{
              borderRadius: `50%`,
              backgroundColor: paletteV2.lime[600],
              marginRight: 12,
              justifyContent: 'center',
              height: 24,
              width: 24,
            }}
          >
            <Icon type="check" htmlColor="white" fontSize="small" />
          </div>
        )}
        {isErrorIconDisplayed && (
          <div
            style={{
              borderRadius: `50%`,
              backgroundColor: paletteV2.cherry[500],
              marginRight: 12,
              justifyContent: 'center',
              height: 24,
              width: 24,
            }}
          >
            <Icon type="exclamation" htmlColor="white" fontSize="large" />
          </div>
        )}

        <div className="message">
          {title && <p className="title">{title}</p>}
          <p>{message}</p>
        </div>
        <div>
          {callToActionName ? (
            <div
              role="button"
              className="cta"
              onClick={this.handleCallToAction}
            >
              <p className="nav">{callToActionName}</p>
            </div>
          ) : null}
          <IconButton
            aria-label="close"
            onClick={this.handleClose}
            style={{
              fontSize: `1rem`,
            }}
          >
            <Icon type="ex" htmlColor={isError && 'white'} />
          </IconButton>
        </div>
      </div>
    ) : null;
  }
}

export default Feedback;
