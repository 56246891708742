import { ApolloLink, NextLink, Operation } from '@apollo/client';

/**
 * An ApolloLink used for logging GraphQL queries and query durations
 */
export default class ApolloOperationTrackingLink extends ApolloLink {
  public readonly operations: Array<{
    operationType: string;
    operationName: string;
    operationDuration: number;
  }> = [];

  constructor() {
    super();
    this.operations = [];
  }

  request(operation: Operation, forward: NextLink) {
    const startTime = new Date().getTime();
    return forward(operation).map(result => {
      const firstDef = operation.query.definitions[0];
      // @ts-ignore Missing type
      const operationType = firstDef.operation;
      const elapsed = new Date().getTime() - startTime;

      this.operations.push({
        operationType,
        operationName: operation.operationName,
        operationDuration: elapsed,
      });

      return result;
    });
  }
}
