import { makeStyles } from '@material-ui/core';
import React from 'react';
import PrimaryButton, { PrimaryButtonProps } from './PrimaryButton';

interface DestructiveButtonProps extends PrimaryButtonProps {}

const useStyles = makeStyles(theme => ({
  destructive: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: '#D32954',
      boxShadow: 'none',
    },
    '&.Mui-disabled, &.Mui-disabled:hover': {
      backgroundColor: theme.palette.error.main,
      opacity: 0.3,
      color: 'white',
    },
  },
}));

const DestructiveButton: React.FC<DestructiveButtonProps> = React.forwardRef(
  ({ children, ...props }, ref) => {
    const classes = useStyles();
    return (
      <PrimaryButton
        className={classes.destructive}
        variant="contained"
        {...props}
        ref={ref}
      >
        {children}
      </PrimaryButton>
    );
  }
);

export default DestructiveButton;
