import { Button } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { SelectDropdownProps } from './config';
import Icon, { IconProps } from '../Icon';
import IconButton from '../IconButton/IconButton';
import useSelectDropdownStyles from './selectDropdownStyles';

const IconDropdownMenu = ({
  classes,
  icon,
  iconSize,
  htmlIconColor,
  menuAnchorEl,
  onClick,
  disabled,
  isOpen,
  buttonLabel = 'show',
}: {
  classes: ReturnType<typeof useSelectDropdownStyles>;
  icon: NonNullable<SelectDropdownProps['icon']>;
  iconSize?: IconProps['fontSize'];
  htmlIconColor?: string;
  menuAnchorEl: null | HTMLElement;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  disabled: SelectDropdownProps['disabled'];
  isOpen: boolean;
  buttonLabel?: string;
}) => (
  <IconButton
    tabIndex={0}
    aria-expanded={Boolean(menuAnchorEl)}
    aria-label={buttonLabel}
    className={isOpen ? classes.buttonIcon_isOpen : classes.buttonIcon_isClosed}
    disabled={disabled}
    onClick={onClick}
  >
    {<Icon type={icon} fontSize={iconSize} htmlColor={htmlIconColor} />}
  </IconButton>
);

const SelectDropdownMenu = ({
  buttonClassName,
  buttonOverrideStyle,
  buttonSize,
  buttonLabel = undefined,
  children,
  classes,
  disabled,
  icon,
  iconSize,
  htmlIconColor,
  id,
  listItems,
  menuAnchorEl,
  menuRef,
  onClick,
  value,
  isOpen,
}: Pick<
  SelectDropdownProps,
  | 'buttonClassName'
  | 'buttonOverrideStyle'
  | 'buttonSize'
  | 'buttonLabel'
  | 'disabled'
  | 'icon'
  | 'iconSize'
  | 'htmlIconColor'
  | 'id'
  | 'listItems'
  | 'value'
> & {
  children: React.ReactNode;
  classes: ReturnType<typeof useSelectDropdownStyles>;
  menuAnchorEl: null | HTMLElement;
  menuRef: React.MutableRefObject<HTMLButtonElement>;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  isOpen: boolean;
}) => {
  const defaultDropdownWithSingleValue = !icon && listItems.length === 1;
  if (icon) {
    return (
      <IconDropdownMenu
        buttonLabel={buttonLabel}
        classes={classes}
        disabled={disabled}
        htmlIconColor={htmlIconColor}
        icon={icon}
        iconSize={iconSize}
        menuAnchorEl={menuAnchorEl}
        onClick={onClick}
        isOpen
      />
    );
  }
  if (defaultDropdownWithSingleValue) {
    return (
      <Button
        className={isOpen ? classes.button_isOpen : classes.button_isClosed}
        aria-label={buttonLabel}
        disabled={Boolean(true)}
        size={buttonSize}
        type="button"
      >
        {listItems[0]?.value}
      </Button>
    );
  }
  return (
    <Button
      aria-controls={menuAnchorEl ? id : undefined}
      aria-label={buttonLabel}
      aria-disabled={disabled}
      aria-expanded={Boolean(menuAnchorEl)}
      className={
        buttonOverrideStyle ??
        clsx(
          isOpen ? classes.button_isOpen : classes.button_isClosed,
          buttonClassName
        )
      }
      disabled={disabled}
      onClick={onClick}
      ref={menuRef}
      size={buttonSize}
      type="button"
      value={value}
    >
      {children}
      {
        <div className={classes.icon}>
          {!menuAnchorEl ? (
            <Icon
              fontSize={iconSize}
              type="chevronDown"
              htmlColor={htmlIconColor}
            />
          ) : (
            <Icon
              fontSize={iconSize}
              type="chevronUp"
              htmlColor={htmlIconColor}
            />
          )}
        </div>
      }
    </Button>
  );
};

export default SelectDropdownMenu;
