import React, { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { CookiesProvider, useCookies } from 'react-cookie';

export enum AppCookieKeys {
  PreviousProject = 'previous_project',
}

/**
 * In order to allow the user to be redirected to the previous project they touched,
 * we need to poll the router events and check if the user is navigating away from
 * a `/dashboard/studio/[projectId]` route and record the projectId into the cookies
 *  if they did.
 *
 * NOTE: This needs to exist at the _app.js layer in order for the lifecycle to not
 *        be interrupted by normal nextjs routing (i.e. projects => studio)
 *
 * See: https://nextjs.org/docs/api-reference/next/router#routerevents
 */
const PreviousProjectCookieHandler = () => {
  const [, setCookies] = useCookies();
  const router = useRouter();

  const handleBeforeHistoryChange = useCallback(() => {
    const id = router?.query?.projectId;
    if (id) {
      setCookies(AppCookieKeys.PreviousProject, JSON.stringify({ id }), {
        maxAge: 60 * 60 * 24, // 1 day
        sameSite: true, // SameSite='Strict'
      });
    }
  }, [router, setCookies]);

  useEffect(() => {
    router?.events.on('beforeHistoryChange', handleBeforeHistoryChange);
    return () => {
      router?.events.off('beforeHistoryChange', handleBeforeHistoryChange);
    };
  }, [handleBeforeHistoryChange, router]);

  return null;
};

const AppCookieProvider = ({ children }: { children: React.ReactNode }) => (
  <CookiesProvider>
    <PreviousProjectCookieHandler />
    {children}
  </CookiesProvider>
);

export default AppCookieProvider;
