type NoOp = () => void;

/**
 * Instantiates a promise and exposes resolve/reject externally.
 *
 * @example
 * class ExternalService {
 *  constructor() {
 *    this.isReady = imperativePromise();
 *  }
 *  init() {
 *    return ExternalService.initialize().then(() => this.isReady.resolve(); )
 *  }
 *  useService() {
 *    this.isReady.then(() => {
 *      // if the service is not initialized, it'll wait until it is to perform this task.
 *    })
 *  }
 * }
 */
export default function imperativePromise() {
  let outerResolve: NoOp;
  let outerReject: NoOp;
  // const promise: Promise<void> & { resolve: NoOp; reject: NoOp } = new Promise<void>((resolve, reject) => {
  const promise = new Promise<void>((resolve, reject) => {
    outerResolve = resolve;
    outerReject = reject;
  });
  // @ts-ignore
  promise.resolve = outerResolve!;
  // @ts-ignore
  promise.reject = outerReject!;
  return promise as typeof promise & { resolve: NoOp; reject: NoOp };
}
