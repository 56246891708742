import React from 'react';
import { Button } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';

const TextButton: React.FC<ButtonProps> = React.forwardRef(
  ({ children, ...props }, ref) => {
    return (
      <Button variant="text" {...props} ref={ref}>
        {children}
      </Button>
    );
  }
);

export default TextButton;
