import React from 'react';
import { makeStyles, CircularProgress } from '@material-ui/core';
import { CircularProgressProps } from '@material-ui/core/CircularProgress';

interface CircularLoaderProps extends CircularProgressProps {}

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.grey['700'],
  },
}));

const CircularLoader: React.FC<CircularLoaderProps> = ({ size, ...props }) => {
  const classes = useStyles();
  return (
    <CircularProgress
      className={classes.root}
      variant="indeterminate"
      color="inherit"
      size={size ?? 24}
      {...props}
    />
  );
};

export default CircularLoader;
