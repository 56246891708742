import isBrowser from '../util/isBrowser';
import EventTrackingService, { EventName } from './EventTrackingService';

let hasTriggeredUnauthorizedRedirect = false;

/**
 * Helper method used for redirecting a user back through the authentication flow. This should
 * be called in response to an Unauthorized API call or session expiration. The
 * `hasTriggeredUnauthorizedRedirect` variable is used to prevent multiple redirects in one session.
 */
// eslint-disable-next-line import/prefer-default-export
export function handleClientUnauthorizedResponse({
  detectionLocation,
}: {
  detectionLocation: string;
}) {
  if (isBrowser && !hasTriggeredUnauthorizedRedirect) {
    // eslint-disable-next-line no-console
    console.warn(`Unauthorized request, redirecting to auth flow...`);
    hasTriggeredUnauthorizedRedirect = true;
    EventTrackingService.track(
      {
        type: EventName.UnauthenticatedRedirect,
        data: {
          path: window.location.pathname,
          detectionLocation,
        },
      },
      {
        send_immediately: true,
      }
    );
    // NOTE: using setTimeout to ensure EventTrackingService.track fires before page unload
    setTimeout(() => {
      const reauthUrl = `${window.location.protocol}//${
        window.location.host
      }/auth/sign_in_redirect?redirect_to=${encodeURIComponent(
        window.location.pathname
      )}`;
      // @ts-ignore
      window.location = reauthUrl;
    }, 0);
  }
}
