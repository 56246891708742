import React, { useContext, createContext, useMemo, ReactNode } from 'react';
import create from 'zustand';

export enum FeedbackIconType {
  Success = 'success',
  Error = 'error',
}

interface FeedbackProps {
  title?: string;
  message: string;
  onClose?: () => void;
  callToAction?: {
    onClick: () => void;
    name: string;
  };
  isError?: boolean;
  iconType?: FeedbackIconType;
}

interface AppContextProps {
  onFeedback: (feedbackProps: FeedbackProps) => void;
  studioToolbarOpen: boolean;
  bannerVisible: boolean;
  setStudioToolbarOpen: (open: boolean) => void;
  setBannerVisible: (open: boolean) => void;
}

const getInitialState = (): AppContextProps => ({
  onFeedback: () => {},
  studioToolbarOpen: false,
  bannerVisible: false,
  setStudioToolbarOpen: () => {},
  setBannerVisible: () => {},
});

const AppContext = createContext<AppContextProps>(getInitialState());
AppContext.displayName = 'AppContext';

const usePersistentState = create<ReturnType<typeof getInitialState>>(set => ({
  studioToolbarOpen: false,
  bannerVisible: false,
  setStudioToolbarOpen: (open: boolean) =>
    set(() => ({ studioToolbarOpen: open })),
  setBannerVisible: (visible: boolean) =>
    set(() => ({ bannerVisible: visible })),
  onFeedback: () => {},
}));

/**
 * Supporting legacy `onFeedback` implementation that is defined in _app.js instead of this
 * context. We should move that logic into this context at some point.
 */
export const AppProvider = ({
  onFeedback,
  children,
}: {
  onFeedback: AppContextProps['onFeedback'];
  children: ReactNode;
}) => {
  const state = usePersistentState();
  const memoizedStateWithFeedback = useMemo(() => {
    return { ...state, onFeedback };
  }, [state, onFeedback]);

  return (
    <AppContext.Provider value={memoizedStateWithFeedback}>
      {children}
    </AppContext.Provider>
  );
};

export function useAppContext() {
  return useContext(AppContext);
}

export default AppContext;
