import { Observable } from '@apollo/client/utilities';

/**
 * Utility method that allows a Promise to behave like an Observable.
 *
 * @see https://github.com/apollographql/apollo-link/issues/646#issuecomment-423279220
 */
const promiseToObservable = (promise: Promise<any>) =>
  // @ts-ignore Types for subscriber not up to date
  new Observable(subscriber => {
    promise.then(
      value => {
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      err => subscriber.error(err)
    );
    return subscriber; // this line can removed, as per next comment
  });

export default promiseToObservable;
