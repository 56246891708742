import React from 'react';
import clsx from 'clsx';
import { Box, Menu, MenuItem } from '@material-ui/core';
import { SelectDropdownProps } from './config';
import Icon from '../Icon';
import theme from '../../theme';
import useSelectDropdownStyles from './selectDropdownStyles';

const SelectDropdownChild = ({
  anchorOrigin,
  classes,
  disablePortal,
  icon,
  id,
  listItems,
  menuAnchorEl,
  onClose,
  onOptionClick,
  transformOrigin,
  value,
  menuPaperWidth,
  menuWidth,
}: Pick<
  SelectDropdownProps,
  | 'anchorOrigin'
  | 'buttonSize'
  | 'disabled'
  | 'disablePortal'
  | 'icon'
  | 'id'
  | 'listItems'
  | 'transformOrigin'
  | 'value'
> & {
  classes: ReturnType<typeof useSelectDropdownStyles>;
  menuAnchorEl: null | HTMLElement;
  onClose: (event: React.MouseEvent<HTMLElement>) => void;
  onOptionClick: (value: string) => void;
  menuPaperWidth: number | string | undefined;
  menuWidth?: number;
}) => {
  if (listItems.length <= 1 && !icon) {
    return null;
  }
  return (
    <Menu
      id={id}
      anchorEl={menuAnchorEl}
      open={Boolean(menuAnchorEl)}
      onClose={onClose}
      getContentAnchorEl={null}
      anchorOrigin={
        anchorOrigin ?? {
          vertical: 'bottom',
          horizontal: 'left',
        }
      }
      transformOrigin={
        transformOrigin ?? {
          vertical: 'top',
          horizontal: 'left',
        }
      }
      MenuListProps={{ disablePadding: true }}
      PopoverClasses={{ paper: classes.menuPaper }}
      style={{ width: menuPaperWidth }}
      // props intentionally not used for performance considerations.
      // keepMounted
      disablePortal={disablePortal}
    >
      {listItems.map(
        (
          {
            key,
            className,
            height,
            divider,
            disabled: itemDisabled,
            disabledYetInteractive,
            children,
            value: itemValue,
          },
          index
        ) => (
          <MenuItem
            key={key}
            className={clsx(classes.menuItemWrapper, {
              [classes.disabled]: disabledYetInteractive,
            })}
            divider={Boolean(divider)}
            style={{
              paddingBottom: divider ? 8 : 'unset',
            }}
            onClick={() =>
              disabledYetInteractive ? () => {} : onOptionClick(itemValue)
            }
            selected={value === itemValue}
            value={itemValue}
            disabled={Boolean(itemDisabled)}
            disableGutters
          >
            <Box
              className={clsx(classes.menuItem, className)}
              style={{
                height: height || 'unset',
                width: menuWidth ? menuWidth - theme.spacing(2) : '100%',
              }}
            >
              {children}
              {value === itemValue && (
                <Icon type="check" className={classes.icon} />
              )}
            </Box>
            {index === listItems.length - 1 && (
              <Box className={classes.menuBottomSpacer} />
            )}
          </MenuItem>
        )
      )}
    </Menu>
  );
};

export default SelectDropdownChild;
