import { createMuiTheme } from '@material-ui/core';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import { SHARED_WHITE, paletteV2 } from '../palette';
import theme from '../index';

type MinimalPaletteOptions = Pick<PaletteOptions, 'ui'>;

const defaultFontFamily = `"Assistant", sans-serif`;
const titleFontFamily = `"DM Sans"`;

const baseType = {
  lineHeight: 'normal',
  fontWeight: 'normal' as const,
  textTransform: 'none' as const,
};

export const typography: TypographyOptions = {
  htmlFontSize: 16,
  fontFamily: defaultFontFamily,
  /**
   * Headline 1-5
   */
  h1: {
    ...baseType,
    fontFamily: titleFontFamily,
    fontSize: `3.375rem`,
  },
  h2: {
    fontFamily: titleFontFamily,
    fontSize: `2.5rem`,
    ...baseType,
  },
  h3: {
    fontFamily: titleFontFamily,
    fontSize: `2rem`,
    ...baseType,
  },
  h4: {
    fontFamily: titleFontFamily,
    fontSize: `1.5rem`,
    ...baseType,
  },
  h5: {
    fontFamily: titleFontFamily,
    fontSize: `1rem`,
    ...baseType,
  },
  /**
   * Paragraph 1-5
   */
  body1: {
    fontSize: `1.5rem`,
    ...baseType,
  },
  body2: {
    fontSize: `1.25rem`,
    ...baseType,
  },
  subtitle1: {
    fontSize: `1rem`,
    ...baseType,
  },
  subtitle2: {
    fontSize: `0.875rem`,
    ...baseType,
  },
  caption: {
    fontSize: `12px`,
    ...baseType,
  },
};

const palette: MinimalPaletteOptions = {
  ui: {
    button: {
      primary: {
        surface: {
          default: paletteV2.grey[1000],
          hover: paletteV2.grey[800],
        },
        text: {
          default: SHARED_WHITE,
        },
      },
      secondary: {
        surface: {
          default: SHARED_WHITE,
          hover: paletteV2.grey[25],
        },
        text: {
          default: paletteV2.grey[1000],
        },
        border: {
          default: `${paletteV2.grey[1000]} !important`,
        },
        hover: {
          boxShadow: {
            default: `0px 1px 3px 1px rgba(0, 0, 0, 0.13), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)`,
          },
        },
        focus: {
          border: {
            default: `${paletteV2.grey[1000]} !important`,
          },
          outline: {
            default: `#8B8B8B !important`,
          },
        },
      },
    },
    background: {
      surface: {
        default: paletteV2.grey[20],
      },
    },
    text: {
      primary: paletteV2.grey[1000],
      secondary: paletteV2.grey[700],
      tertiary: paletteV2.grey[400],
    },
    input: {
      primary: {
        text: {
          default: paletteV2.grey[700],
          placeholder: paletteV2.grey[400],
          label: paletteV2.grey[700],
        },
        surface: {
          default: SHARED_WHITE,
        },
        stroke: {
          default: paletteV2.grey[75],
          focus: paletteV2.grey[700],
          active: paletteV2.grey[700],
        },
      },
      password: {
        icon: {
          default: '#000',
        },
      },
    },
  },
};

export default createMuiTheme(theme, {
  typography,
  palette: {
    ...theme.palette,
    type: 'light',
    ...palette,
  },
});
