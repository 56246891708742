export interface SemanticVersion {
  major: number;

  minor: number;

  patch: number;

  suffix?: string;

  versionString: string;
}

export enum CompareSemanticVersionsResult {
  MajorChanged,
  MinorChanged,
  PatchChanged,
  NotChanged,
}

const regExp =
  /^([0-9]+)\.([0-9]+)\.([0-9]+)(?:-([0-9A-Za-z-]+(?:\.[0-9A-Za-z-]+)*))?(?:\+[0-9A-Za-z-]+)?$/g;

export const isSemanticVersion = (input: string) => {
  if (input?.match(regExp)) {
    return true;
  }
  return false;
};

export const parseSemanticVersion = (
  input: string | undefined
): SemanticVersion | null => {
  if (!input || !isSemanticVersion(input)) {
    return null;
  }

  const [version, suffix] = input.split('-');

  const [major, minor, patch] = version
    .split('.')
    .map(v => Number.parseInt(v, 10));

  return {
    major,
    minor,
    patch,
    suffix,
    versionString: input,
  };
};

export const compareSemanticVersions = (
  version1: SemanticVersion,
  version2: SemanticVersion
): CompareSemanticVersionsResult => {
  if (version1.major !== version2.major) {
    return CompareSemanticVersionsResult.MajorChanged;
  }

  if (version1.minor !== version2.minor) {
    return CompareSemanticVersionsResult.MinorChanged;
  }

  if (version1.patch !== version2.patch) {
    return CompareSemanticVersionsResult.PatchChanged;
  }

  return CompareSemanticVersionsResult.NotChanged;
};
