import getConfig from 'next/config';

declare global {
  interface Window {
    gtag: Gtag.Gtag;
  }
}

export const getGATrackingId = () => {
  const { publicRuntimeConfig } = getConfig();
  const { GA_TRACKING_ID } = publicRuntimeConfig;
  return GA_TRACKING_ID;
};

export const getGATagManagerId = () => {
  const { publicRuntimeConfig } = getConfig();
  const { GA_TAG_MANAGER_ID } = publicRuntimeConfig;
  return GA_TAG_MANAGER_ID;
};

// Inspired by:
// https://github.com/zeit/next.js/blob/canary/examples/with-google-analytics/lib/gtag.js

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  const GA_TRACKING_ID = getGATrackingId();
  if (typeof GA_TRACKING_ID !== 'undefined') {
    window.gtag('config', GA_TRACKING_ID, {
      page_location: url,
    });
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
interface EventArgs {
  action: string;
  category?: string;
  label?: string;
  value?: any;
  customMetrics?: Object;
}
export const event = ({
  action,
  category,
  label,
  value,
  customMetrics = {},
}: EventArgs) => {
  const GA_TRACKING_ID = getGATrackingId();
  if (typeof GA_TRACKING_ID !== 'undefined') {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value,
      ...customMetrics,
    });
  }
};

interface UpgradeModalViewArgs {
  from: string;
  clipsGenerated: number;
  accountAgeInSeconds: number;
}
export const trackUpgradeModalView = ({
  from,
  clipsGenerated,
  accountAgeInSeconds,
}: UpgradeModalViewArgs) => {
  const GA_TRACKING_ID = getGATrackingId();
  if (typeof GA_TRACKING_ID !== 'undefined') {
    registerCustomMetrics();
    event({
      action: 'Open Upgrade Modal',
      category: 'Upgrade Path',
      label: from,
      value: 1,
      customMetrics: {
        clipsGenerated,
        accountAgeInSeconds,
      },
    });
  }
};

interface UpgradePathArgs {
  from: string;
  clipsGenerated: number;
  accountAgeInSeconds: number;
}
export const trackAccountUpgrade = ({
  from,
  clipsGenerated,
  accountAgeInSeconds,
}: UpgradePathArgs) => {
  const GA_TRACKING_ID = getGATrackingId();
  if (typeof GA_TRACKING_ID !== 'undefined') {
    registerCustomMetrics();
    event({
      action: 'Account Upgrade',
      category: 'Upgrade Path',
      label: from,
      value: 1,
      customMetrics: {
        clipsGenerated,
        accountAgeInSeconds,
      },
    });
  }
};

export const trackTrialConversion = () => {
  const GA_TRACKING_ID = getGATrackingId();
  if (typeof GA_TRACKING_ID !== 'undefined') {
    window.gtag('event', 'conversion', {
      send_to: 'AW-709797513/oO8RCJqzgNcBEInNutIC',
    });
  }
};

export const trackPaidConversion = () => {
  const GA_TRACKING_ID = getGATrackingId();
  if (typeof GA_TRACKING_ID !== 'undefined') {
    window.gtag('event', 'conversion', {
      send_to: 'AW-709797513/hkx2CIejlfoCEInNutIC',
    });
  }
};

function registerCustomMetrics() {
  const GA_TRACKING_ID = getGATrackingId();
  if (typeof GA_TRACKING_ID !== 'undefined') {
    window.gtag('config', GA_TRACKING_ID, {
      custom_map: {
        // NOTE: these _must_ be defined in GA under Property > Custom Definitions > Custom Metrics
        metric1: 'clipsGenerated',
        metric2: 'accountAgeInSeconds',
      },
    });
  }
}
