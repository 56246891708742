import React, {
  createContext,
  useContext,
  useMemo,
  useReducer,
  Reducer,
} from 'react';
import PropTypes from 'prop-types';
import reducer from './reducer';
import Prompts from './index.d';

export const getInitialState = (): Prompts.ContextState => ({
  activePrompt: null,
  activePromptPayload: null,
});

export const PromptsContext = createContext<Prompts.ContextProps>([
  getInitialState(),
  // @ts-ignore {actions are initialized in `PromptsProvider`}
  () => {},
]);
PromptsContext.displayName = 'PromptsContext';

export function usePromptsContext(): Prompts.ContextProps {
  return useContext(PromptsContext);
}

export const PromptsProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer<
    Reducer<Prompts.ContextState, Prompts.ContextActions>
  >(reducer, getInitialState());
  const promptsContextValue: Prompts.ContextProps = useMemo(
    () => [state, dispatch],
    [state, dispatch]
  );

  return (
    <PromptsContext.Provider value={promptsContextValue}>
      {children}
    </PromptsContext.Provider>
  );
};

PromptsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
