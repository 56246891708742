import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import paragraphSplitter from '../context/editor/paragraphSplitter';
import splitIntoSentences from '../context/editor/sentenceSplitter';
import { EditMode, EditorError, EditorState, Script } from './EditorSlice';
import { clickCue, resetCueOptions } from './CuesSlice';
import { AppState } from '.';
import { EditorRefOrNull } from '../context/editorV2/CoreEditorContext';

export const SENTENCE_LIMIT = 96;
export const PARAGRAPH_LIMIT = 48;

export const getTrimmedCharacters = (text: string): string => text.trim();

type GenerateStateOptions = Pick<EditorState, 'renderMode'> &
  EditorState['script'];

/**
 * Copy the given state to create new state based on the state options
 * - Update render mode
 * - Update script with text and text format
 * - Generate final script from script
 * - Validate generated state for error and append error
 */
export const generateState = (
  state: EditorState,
  options: GenerateStateOptions
): EditorState => {
  const { renderMode, text, textFormat, json } = options;
  const finalScript = generateFinalScript(renderMode, {
    text,
    textFormat,
  });

  const updatedState = {
    ...state,
    script: {
      text: getTrimmedCharacters(text),
      textFormat,
      json,
    },
    renderMode,
    finalScript,
  };
  const error = validate(updatedState);

  return {
    ...updatedState,
    error,
  };
};

export const generateFinalScript = (
  renderMode: EditorState['renderMode'],
  partialScript: Pick<Script, 'text' | 'textFormat'>
) => {
  const { text, textFormat } = partialScript;
  const rawInput = getTrimmedCharacters(text);
  const shouldUseFormattedText = textFormat !== '';
  const input = shouldUseFormattedText ? textFormat : rawInput;

  let script = [];
  let plainTextArray = [];
  let sentencesUsed = 0;
  let paragraphsUsed = 0;

  switch (renderMode) {
    case 'sentence':
      plainTextArray = splitIntoSentences(rawInput).map(getTrimmedCharacters);
      script = splitIntoSentences(input).map(getTrimmedCharacters);
      sentencesUsed = script.length;
      break;
    case 'paragraph':
      plainTextArray = paragraphSplitter(rawInput).map(getTrimmedCharacters);
      script = paragraphSplitter(input).map(getTrimmedCharacters);
      paragraphsUsed = script.length;
      break;
    default:
      plainTextArray = [rawInput];
      script = [input];
      break;
  }

  return {
    sentencesUsed,
    paragraphsUsed,
    plainTextArray,
    scriptArrayToRender: script,
    charactersUsed: rawInput.length,
  };
};

export const validate = (state: EditorState): EditorError | undefined => {
  // Assert character limit
  const characterLimitErr = assertErrorIfExceedsCharacterLimit(
    state.finalScript.plainTextArray,
    state.characterLimit,
    state.renderMode,
    state.shouldBeSingleTakeOnly
  );

  if (characterLimitErr) return { message: characterLimitErr };

  // Assert based on render mode

  if (state.renderMode === 'paragraph') {
    const paragraphErr = assertErrorIfExceedsParagraphLimit(
      state.finalScript.paragraphsUsed
    );
    if (paragraphErr) return { message: paragraphErr };
  }

  if (state.renderMode === 'sentence') {
    const sentenceErr = assertErrorIfExceedsSentenceLimit(
      state.finalScript.sentencesUsed
    );
    if (sentenceErr) return { message: sentenceErr };
  }
};

const assertErrorIfExceedsCharacterLimit = (
  chunks: string[],
  characterLimit: number,
  renderMode: string,
  shouldBeSingleTakeOnly: boolean
) => {
  // Look for chunks that surpass the character limit
  const longChunkErrorIndex = chunks.findIndex(
    chunk => chunk.length > characterLimit
  );

  if (longChunkErrorIndex >= 0) {
    if (renderMode === 'all') {
      const changeRenderModeMessage = 'Please switch to ‘Render by paragraph’.';
      const generalReduceLimitMessage = 'Please reduce character count.';

      const actionMessage = shouldBeSingleTakeOnly
        ? generalReduceLimitMessage
        : changeRenderModeMessage;

      return `You’ve exceeded ${characterLimit.toLocaleString()} characters. ${actionMessage}`;
    }
    return `Section of text is longer than ${characterLimit.toLocaleString()} characters. Please shorten ${renderMode} that starts with '${chunks[
      longChunkErrorIndex
    ].slice(0, 15)}'`;
  }
};

const assertErrorIfExceedsParagraphLimit = (paragraphs: number) => {
  if (paragraphs > PARAGRAPH_LIMIT)
    return `You may only render a maximum of ${PARAGRAPH_LIMIT} paragraphs at a time.`;
};

const assertErrorIfExceedsSentenceLimit = (sentences: number) => {
  if (sentences > SENTENCE_LIMIT)
    return `You may only render a maximum of ${SENTENCE_LIMIT} sentences at a time.`;
};

export const performUndoOrRedoSideEffects = ({
  dispatch,
  getState,
  coreEditorRef,
}: {
  dispatch: ThunkDispatch<AppState, unknown, AnyAction>;
  getState: () => AppState;
  coreEditorRef: EditorRefOrNull;
}) => {
  const { editMode } = getState().editor;
  if (editMode === EditMode.CUES) {
    const activeCue = coreEditorRef?.helpers.findActiveCue();
    if (activeCue) {
      dispatch(clickCue(activeCue));
    } else {
      dispatch(resetCueOptions());
      coreEditorRef?.commands.removeActiveTokenSelection();
    }
  }
};
