import React, { useCallback, useState, useRef, forwardRef } from 'react';
import clsx from 'clsx';
import { Button, makeStyles } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { SelectDropdownListItem } from '../SelectDropdown/config';
import { IconType } from '../Icon';
import SelectDropdownV2 from '../SelectDropdownV2';
import useSelectDropdownStyles from '../SelectDropdown/selectDropdownStyles';
import LoadingIcon from '../LoadingIndicators/CircularLoader';

interface OnButtonClickData {
  value: string;
  loading: boolean;
}

export interface PrimaryButtonWithDropdown extends ButtonProps {
  /**
   * id prop to be used on the SelectDropdown Menu element
   */
  id: string;
  /**
   * The items to display in the menu
   */
  listItems: SelectDropdownListItem[];
  /**
   * The value of the selected list item from the list of items
   */
  selectedListItem: string;
  /**
   * Hook called when the user click the button.
   * Gives the value of the selected list item as a string in the paramter.
   */
  onButtonClick: (data: OnButtonClickData) => void;
  /**
   * Hook called when the user selects a list item.
   * Gives the value of the selected list item as a string in the parameter.
   */
  onDropdownSelect: (value: string) => void;
  /**
   * Indicates whether to show a loading state for the primary button
   */
  loading?: boolean;
  /**
   * Text that should display alongside the loading state
   */
  loadingText?: string;
  /**
   * Disables the primary button
   */
  primaryButtonDisabled?: boolean;
  /**
   * Disables the caret button and dropdown menu
   */
  selectDropdownDisabled?: boolean;
  /**
   * Props to be applied to the MUI Button
   */
  buttonProps?: ButtonProps | undefined;
  /**
   * Number representing the width of the entire component
   */
  buttonWidth?: number;

  /**
   * small component has height of 32px, 12px font, and small icon;
   * large component has height of 48px, 14px font, and large icon
   */
  componentSize?: 'small' | 'large';
  /**
   * Aria label for the dropdown button
   */
  selectDropdownButtonLabel?: string;
  /**
   * Flag for whether updating the dropdown will click the button
   */
  clickButtonOnSelect?: boolean;
}

type StyleProps = {
  buttonWidth: number;
  color: ButtonProps['color'];
  selectDropdownDisabled: boolean;
  componentSize: 'small' | 'large';
};

const useStyles = makeStyles(theme => ({
  root: ({
    buttonWidth,
    color,
    selectDropdownDisabled,
    componentSize,
  }: StyleProps) => ({
    display: 'inline-flex',
    width: `${buttonWidth}px`,
    height: componentSize === 'small' ? theme.spacing(4) : theme.spacing(6),
    '& > button:last-of-type': {
      ...theme?.overrides?.MuiButton?.contained,
      ...(color === 'primary'
        ? theme?.overrides?.MuiButton?.containedPrimary
        : theme?.overrides?.MuiButton?.containedSecondary),
      borderRadius: 0,
      borderTopRightRadius: theme.spacing(0.5),
      borderBottomRightRadius: theme.spacing(0.5),
      background:
        color === 'primary'
          ? theme.palette.primary.main
          : theme.mixins.palette.buttons.secondary.default,
      color: 'rgba(0, 0, 0, 0.87)',
      minWidth: componentSize === 'small' ? theme.spacing(4) : theme.spacing(6),
      '&:disabled': {
        cursor: 'not-allowed !important',
        opacity: selectDropdownDisabled ? 0.3 : 0.5,
      },
      '&:disabled:hover': {
        cursor: 'not-allowed !important',
        opacity: selectDropdownDisabled ? 0.3 : 0.5,
      },
      '&.Mui-focusVisible': {
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
      },
    },
  }),
  button: {
    '&.MuiButton-containedSecondary': {
      color: theme.palette.common.white,
    },
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    width: '100%',
    fontSize: ({ componentSize }: { componentSize: 'small' | 'large' }) =>
      componentSize === 'small' ? theme.spacing(1.5) : theme.spacing(1.75),
  },
}));

/**
 * This component shows a primary button conjoined with a select dropdown menu.
 */
const PrimaryButtonWithDropdown: React.FC<PrimaryButtonWithDropdown> =
  forwardRef((props, ref) => {
    const {
      id,
      listItems,
      selectedListItem,
      onButtonClick,
      color,
      onDropdownSelect,
      buttonProps = undefined,
      loading = false,
      loadingText,
      clickButtonOnSelect,
      primaryButtonDisabled = false,
      selectDropdownDisabled = false,
      selectDropdownButtonLabel = undefined,
      buttonWidth = 245,
      componentSize = 'large',
    } = props;

    const widthRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const selectDropdownStyles = useSelectDropdownStyles({});
    const classes = useStyles({
      buttonWidth,
      color,
      selectDropdownDisabled,
      componentSize,
    });

    const handleButtonClick = useCallback(() => {
      onButtonClick({ value: selectedListItem, loading });
    }, [onButtonClick, selectedListItem, loading]);

    const handleSelectChange = useCallback(
      value => {
        onDropdownSelect(value);
        if (clickButtonOnSelect) {
          onButtonClick({ value, loading });
        }
      },
      [clickButtonOnSelect, loading, onButtonClick, onDropdownSelect]
    );

    const renderedIcon: IconType = isOpen
      ? IconType.chevronUp
      : IconType.chevronDown;

    let iconColor = color === 'primary' ? '#000000' : '#FFFFFF';
    if (selectDropdownDisabled && color === 'primary') {
      iconColor = 'rgba(0, 0, 0, 0.2)';
    }

    return (
      <div className={classes.root} ref={widthRef}>
        <Button
          ref={ref}
          aria-haspopup="false"
          aria-label={selectedListItem}
          aria-busy={loading}
          className={clsx(classes.button, { loading })}
          color={loading ? 'secondary' : color}
          disabled={primaryButtonDisabled}
          onClick={handleButtonClick}
          type="submit"
          value={selectedListItem}
          variant="contained"
          {...buttonProps}
        >
          {loading && (
            <LoadingIcon
              size={componentSize === 'small' ? 16 : 24}
              style={{ marginRight: 5 }}
            />
          )}
          {loading ? loadingText : selectedListItem}
        </Button>
        <SelectDropdownV2
          id={id}
          autoResize
          buttonLabel={selectDropdownButtonLabel}
          classes={selectDropdownStyles}
          disabled={selectDropdownDisabled || loading}
          htmlIconColor={iconColor}
          icon={renderedIcon}
          iconSize={componentSize}
          listItems={listItems}
          onChange={handleSelectChange}
          onOpen={setIsOpen}
          openMenuText={selectedListItem}
          value={selectedListItem}
          widthElement={widthRef}
        />
      </div>
    );
  });

export default PrimaryButtonWithDropdown;
