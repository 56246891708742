import React from 'react';
import { Button } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import LoadingIcon from '../LoadingIndicators/CircularLoader';

interface DefaultButtonProps extends ButtonProps {
  loading?: boolean;
}
/**
 * @todo delete this button once admin is phased out
 */
const DefaultButton: React.FC<DefaultButtonProps> = React.forwardRef(
  function InnerDefaultButton({ children, loading, disabled, ...props }, ref) {
    return (
      <Button
        disabled={loading || disabled}
        variant="outlined"
        color="secondary"
        {...props}
        ref={ref}
      >
        {loading ? <LoadingIcon /> : children}
      </Button>
    );
  }
);

export default DefaultButton;
