import React, { useState } from 'react';
import SVG, { ErrorCallback, FetchError } from 'react-inlinesvg';
import { SvgIcon, SvgIconTypeMap } from '@material-ui/core';

import { AvailableIconTypes } from './types';

const STATIC_ICONS_PATH = '/static/images/untitled_icons/';
const noop: () => void = () => {};
/**
 * Note:
 *  Because 16px is the default we would like, the other sizes (small & large)
 *  are arbitrary until we have a use case.
 */
type fontSize = NonNullable<SvgIconTypeMap['props']['fontSize'] | 'medium'>;
const fontSizeMap: Record<fontSize, string> = {
  small: '0.75rem',
  default: '1rem',
  inherit: 'inherit',
  medium: '1.25rem',
  large: '1.5rem',
};

export interface IconProps {
  /**
   * This should be the filename of the SVG that should be named without the '.svg' extension.
   *  example: type="checkbox"
   */
  type: AvailableIconTypes;
  /**
   * Classnames that should be applied to the MUI SvgIcon wrapper.
   */
  className?: string;
  /**
   * These colors correspond to the MUI SvgIcon Wrapper.
   * https://mui.com/material-ui/api/svg-icon/#main-content
   */
  color?: SvgIconTypeMap['props']['color'];
  /**
   * Sets cursor the on the Mui SvgIcon Wrapper.
   */
  cursor?: React.SVGProps<SVGSVGElement>['cursor'];
  /**
   * Should the component fail to load, the fallback component will be shown to the user.
   */
  fallback?: React.ReactNode;
  /**
   * These font sizes correspond to the MUI SvgIcon Wrapper, however the default that we use for
   * icons is 16px - 1rem.
   *
   * https://mui.com/material-ui/api/svg-icon/#main-content
   */
  fontSize?: fontSize;
  /**
   * If applicable, depending on the type of icon, the filled icon will be loaded.
   *
   * Outlined icons are used by default.
   */
  filled?: boolean;
  /**
   * Applies a color attribute to the MUI SvgIcon Wrapper.
   */
  htmlColor?: SvgIconTypeMap['props']['htmlColor'];
  /**
   * If the icon failed to be loaded via the React InlineSvg component,
   * this callback will be invoked.
   */
  onError?: ErrorCallback;
  /**
   * Css properties that are specified directly to the MUI SvgIcon Wrapper.
   */
  style?: React.CSSProperties;
  /**
   * Provides a human-readable title for the element that contains it.
   * https://www.w3.org/TR/SVG-access/#Equivalent
   */
  titleAccess?: string;
  /**
   * Allows you to redefine what the coordinates without units mean inside an SVG element.
   * For example, if the SVG element is 500 (width) by 200 (height), and you pass viewBox="0 0 50 20",
   * this means that the coordinates inside the SVG will go from the top left corner (0,0)
   * to bottom right (50,20) and each unit will be worth 10px.
   */
  viewBox?: string;
}

const Icon = (props: IconProps) => {
  const {
    type,
    className = '',
    color = 'inherit',
    cursor = undefined,
    fallback = null,
    filled = false,
    fontSize = 'default',
    htmlColor = undefined,
    onError = noop,
    style = {},
    titleAccess = '',
    viewBox = undefined,
  } = props;

  const [hasError, setHasError] = useState(false);
  const handleError = (error: Error | FetchError) => {
    setHasError(true);
    onError(error);
  };

  return (
    <SvgIcon
      color={color}
      className={className}
      cursor={cursor}
      htmlColor={htmlColor}
      style={{ fontSize: fontSizeMap[fontSize], ...style }}
      titleAccess={titleAccess}
      viewBox={viewBox}
    >
      <SVG
        onError={handleError}
        src={`${STATIC_ICONS_PATH}${type}${filled ? 'Filled' : ''}.svg`}
      >
        {hasError && fallback}
      </SVG>
    </SvgIcon>
  );
};

export default Icon;
