import { MutableRefObject } from 'react';
import { EditorRef } from '../../components/EditorV2/hooks/ImperativeHandle';

export type EditorRefOrNull = EditorRef | null;
export type CoreEditorContextValue = MutableRefObject<EditorRefOrNull> | null;

let coreEditorRef: EditorRefOrNull = null;

/**
 * This function provides the access to the core editor via the ImperativeHandle.
 */
export const getCoreEditorRef = (): EditorRefOrNull => {
  return coreEditorRef;
};

export const setCoreEditorRef = (ref: EditorRefOrNull): EditorRefOrNull => {
  if (coreEditorRef !== ref) {
    coreEditorRef = ref;
  }

  return coreEditorRef;
};
