export enum ActiveCueGroup {
  inactive = 0,
  word = 1,
  pause = 2,
}

export enum CueType {
  loudness = 'loudness',
  tempo = 'tempo',
  pause = 'pause',
  pitch = 'pitch',
}

export type StudioCueType = keyof typeof CueType;

export interface StudioCueSliderLabels {
  start: string;
  end: string;
}

export interface StudioCueSliderProps {
  sliderLabels: StudioCueSliderLabels;
}

export interface Cue {
  type: StudioCueType;
  label: string;
  description: string;
  SliderProps: StudioCueSliderProps;
  group: ActiveCueGroup;
}

export const cues: Array<Cue> = [
  {
    type: 'loudness',
    label: 'loudness',
    description: 'Select a word(s) to adjust Loudness',
    SliderProps: {
      sliderLabels: {
        start: 'Quieter',
        end: 'Louder',
      },
    },
    group: ActiveCueGroup.word,
  },
  {
    type: 'tempo',
    // Note: show tempo cue as "pace" to users.
    label: 'pace',
    description: 'Select a word(s) to adjust Pace',
    SliderProps: {
      sliderLabels: {
        start: 'Slower',
        end: 'Faster',
      },
    },
    group: ActiveCueGroup.word,
  },
  {
    type: 'pitch',
    label: 'pitch',
    description: '',
    SliderProps: {
      sliderLabels: {
        start: 'Lower',
        end: 'Higher',
      },
    },
    group: ActiveCueGroup.word,
  },
  {
    type: 'pause',
    label: 'pause',
    description: 'Select a punctuation block to adjust Pause',
    SliderProps: {
      sliderLabels: {
        start: 'Shorter',
        end: 'Longer',
      },
    },
    group: ActiveCueGroup.pause,
  },
];

export default cues;
