import { makeStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { typography as typographyV3 } from '../../theme/light';

/**
 * @see https://www.figma.com/file/TWgty8gcTip6JWbfKJigFb/WL-Styles-v1.0-Breve?node-id=2018:488&mode=dev
 */

export type AllVariations =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h1Serif'
  | 'h2Serif'
  | 'h3Serif'
  | 'h4Serif'
  | 'lg'
  | 'md'
  | 'sm'
  | 'xs'
  | 'h1V3'
  | 'h2V3'
  | 'h3V3'
  | 'h4V3'
  | 'h5V3'
  | 'p1'
  | 'p2'
  | 'p3'
  | 'p4'
  | 'p5';

type BaseTypographyStyleKeys =
  | 'fontFamily'
  | 'fontSize'
  | 'fontStyle'
  | 'fontWeight'
  | 'lineHeight'
  | 'textTransform';

/**
 * @note note this nested `base` object key is here deliberately in case this needs to be extended in
 * arbitary ways
 * IE bold could be potentially different between typographical elements
 * @note in addition note we have harcoded pixel values and the future implementation
 * should use theme.spacing
 * @note we have the line height mirroring the fontSize vs existing on typography to allow
 * more granular spacing control
 */
type BaseVariant = CSSProperties;

const baseTypescale = {
  fontFamily: `Assistant`,
  fontStyle: `normal`,
  textTransform: `none` as const,
  fontWeight: 400,
};

const h1: BaseVariant = {
  ...baseTypescale,
  fontSize: `32px`,
  lineHeight: `42px`,
};

const h2: BaseVariant = {
  ...baseTypescale,
  fontSize: `24px`,
  lineHeight: `31px`,
};

const h3: BaseVariant = {
  ...baseTypescale,
  fontSize: `16px`,
  lineHeight: `21px`,
};

const h4: BaseVariant = {
  ...baseTypescale,
  fontSize: `14px`,
  lineHeight: `18px`,
};

const h5: BaseVariant = {
  ...baseTypescale,
  fontSize: `12px`,
  lineHeight: `16px`,
};

const lg: BaseVariant = {
  ...baseTypescale,
  fontSize: `16px`,
  lineHeight: `21px`,
};
const md: BaseVariant = {
  ...baseTypescale,
  fontSize: `14px`,
  lineHeight: `18px`,
};

const sm: BaseVariant = {
  ...baseTypescale,
  fontSize: `12px`,
  lineHeight: `16px`,
};

const xs: BaseVariant = {
  ...h5,
  fontSize: `11px`,
  lineHeight: `14px`,
};

const baseSerif = {
  fontFamily: 'Frank Ruhl Libre',
  fontStyle: `normal`,
  textTransform: `none` as const,
  fontWeight: 400,
};

const h1Serif: BaseVariant = {
  ...baseSerif,
  fontSize: '56px',
  lineHeight: `72px`,
};

const h2Serif: BaseVariant = {
  ...baseSerif,
  fontSize: '44px',
  lineHeight: `56px`,
};

const h3Serif: BaseVariant = {
  ...baseSerif,
  fontSize: '32px',
  lineHeight: `41px`,
};

const h4Serif: BaseVariant = {
  ...baseSerif,
  fontSize: '22px',
  lineHeight: `28px`,
};

const h1V3: BaseVariant = {
  ...typographyV3.h1,
};
const h2V3: BaseVariant = {
  ...typographyV3.h2,
};
const h3V3: BaseVariant = {
  ...typographyV3.h3,
};
const h4V3: BaseVariant = {
  ...typographyV3.h4,
};
const h5V3: BaseVariant = {
  ...typographyV3.h5,
};
const p1: BaseVariant = {
  ...typographyV3.body1,
};
const p2: BaseVariant = {
  ...typographyV3.body2,
};
const p3: BaseVariant = {
  ...typographyV3.subtitle1,
};
const p4: BaseVariant = {
  ...typographyV3.subtitle2,
};
const p5: BaseVariant = {
  ...typographyV3.caption,
};

const baseTypographyLookup = {
  h1,
  h2,
  h3,
  h4,
  h5,
  lg,
  md,
  sm,
  xs,
  h1Serif,
  h2Serif,
  h3Serif,
  h4Serif,
  h1V3,
  h2V3,
  h3V3,
  h4V3,
  h5V3,
  p1,
  p2,
  p3,
  p4,
  p5,
};

/**
 * @description helper which plucks the typographical
 * metadata and returns a string given a list of base
 * keys
 */
export const pluckMetaDataFromTypography = (
  variant: AllVariations,
  props: BaseTypographyStyleKeys[]
) => {
  let output = '';
  const start = baseTypographyLookup[variant];
  for (const key of props) {
    output += `${key}:${start[key]}, `;
  }
  return output;
};

const useStyles = makeStyles(_theme => ({
  ...baseTypographyLookup,
  bold: { fontWeight: 700 },
  uppercase: { textTransform: 'uppercase' },
  underline: { textDecoration: 'underline' },
}));

export default useStyles;
