import { TypographyOptions } from '@material-ui/core/styles/createTypography';

const defaultFontFamily = `"Assistant", sans-serif`;
const titleFontFamily = `"Frank Ruhl Libre", serif`;

/**
 * @todo Find typography exceptions and extend the typography options
 */
const typography: TypographyOptions = {
  htmlFontSize: 16,
  fontFamily: defaultFontFamily,
  h1: {
    fontFamily: titleFontFamily,
    fontSize: `3.5rem`,
    fontWeight: 'bold',
    lineHeight: 4.5 / 4,
  },
  h2: {
    fontFamily: titleFontFamily,
    fontSize: `2.75rem`,
    fontWeight: 'bold',
    lineHeight: 3.5 / 3,
  },
  h3: {
    fontFamily: titleFontFamily,
    fontSize: `2rem`,
    fontWeight: 'bold',
    lineHeight: 2.5 / 2,
  },
  h4: {
    fontSize: `1rem`,
    fontWeight: 'bold',
    lineHeight: 1.125,
    textTransform: 'uppercase',
  },
  h5: {
    fontSize: `0.75rem`,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    lineHeight: `1rem`,
  },
  body1: {
    fontSize: `1rem`,
  },
  // Small body text
  body2: {
    fontSize: `0.75rem`,
  },
  // Bold titles, same font size as body text
  subtitle1: {
    fontSize: `1rem`,
    lineHeight: `1rem`,
    fontWeight: 'bold',
  },
  // Larger non-bold subtitle, typically used with an h1/h2
  subtitle2: {
    fontSize: `1.375rem`,
    lineHeight: 3 / 2, // 1.875rem
  },
};

export default typography;
