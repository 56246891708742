import { captureException } from '@sentry/node';
import Router from 'next/router';
import React, { ReactElement, useEffect } from 'react';
import Fallback from './Fallback';
import SentryErrorBoundary from './SentryErrorBoundary';

export interface ErrorBoundaryProps {
  children: ReactElement;
}

const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
  useEffect(() => {
    const onRouteChangeError = (error: Error, as: any) => {
      let path = 'unkown';
      if (error.message.includes('Route Cancelled')) {
        // Ignore the route cancellation, likely due to redirection and not an error
        console.warn(`Route cancelled at path: ${path}`);
        return;
      }
      if (typeof as === 'string') {
        path = as;
      } else if (typeof as.pathname === 'string') {
        path = as.pathname;
      }
      const modifiedError = new Error(
        `Route change error at path [${path}]: ${error.message}`
      );
      modifiedError.stack = error.stack;
      modifiedError.name = error.name;
      captureException(modifiedError);
    };
    Router.events.on('routeChangeError', onRouteChangeError);
    return () => {
      Router.events.off('routeChangeError', onRouteChangeError);
    };
  }, []);

  return (
    <SentryErrorBoundary fallback={Fallback}>{children}</SentryErrorBoundary>
  );
};

export default ErrorBoundary;
