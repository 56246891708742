import { ButtonBase, makeStyles } from '@material-ui/core';
import { ButtonBaseProps } from '@material-ui/core/ButtonBase';
import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { uppercaseFirst } from '../../util/stringFormatting';
import Typography from '../Typography';
import paletteV2 from '../Typography/typographyPalette';

/**
 * IconButton currently only provides a button varient that transitions
 * the underlying SvgIcon betwen shades of grey/white. We will have to think this through
 * a bit further to support different color icon buttons.
 */

const useStyles = makeStyles(theme => ({
  root: {
    padding: (props: IconButtonProps) =>
      props.label ? theme.spacing(1, 2) : theme.spacing(1),

    '&:hover': {
      borderRadius: theme.spacing(0.5),
    },
    '&.Mui-focusVisible': {
      borderRadius: theme.spacing(0.5),
      '&:not(&[data-hide-focus])': {
        ...theme.mixins.focusOutline,
        outlineOffset: 2,
        // Override the one from focus outline
        borderRadius: theme.spacing(0.5),
      },
    },
  },
  text: {
    color: 'inherit',
    marginLeft: theme.spacing(1),
    marginBottom: 2,
  },
  // Default (white/gray)
  colorDefault: {
    color: theme.palette.grey.A200,
    '&:hover': {
      color: theme.palette.common.white,
    },
    '&:active': {
      color: theme.palette.grey['500'],
    },
  },
  colorDefaultFocus: {
    color: theme.palette.common.white,
  },
  // Primary
  colorPrimary: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.light,
    },
    '&:active': {
      color: theme.palette.primary.dark,
    },
  },
  colorPrimaryFocus: {
    color: theme.palette.primary.light,
  },
  colorAction: {
    color: paletteV2.grey[0],
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: 'rgba(255, 255, 255, 0.15)',
    },
    '&[aria-disabled="true"], &.Mui-disabled, &.Mui-disabled:hover': {
      cursor: 'not-allowed',
      pointerEvents: 'auto',
      opacity: 0.5,
    },
  },
}));

export interface IconButtonProps
  extends Omit<ButtonBaseProps, 'color' | 'aria-label'> {
  children: ReactElement;
  label?: string;
  'aria-label': string;
  color?: 'default' | 'primary' | 'action';
  href?: string;
  download?: string;
}

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (props, forwardRef) => {
    const { className, children, color = 'default', label, ...rest } = props;
    const classes = useStyles(props);
    // @ts-ignore Does not like the unkown key values
    const colorClassName = classes[`color${uppercaseFirst(color)}`];
    // @ts-ignore Does not like the unkown key values
    const colorFocusClassName = classes[`color${uppercaseFirst(color)}Focus`];
    return (
      <ButtonBase
        ref={forwardRef}
        {...rest}
        className={clsx(classes.root, colorClassName, className)}
        focusVisibleClassName={colorFocusClassName}
      >
        {React.cloneElement(children, { color: 'inherit' })}
        {label && (
          <Typography className={classes.text} variant="caption">
            {label}
          </Typography>
        )}
      </ButtonBase>
    );
  }
);

export default IconButton;
