import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { isNumber } from 'lodash';
import { AppState } from './index';
import { getCoreEditorRef } from '../context/editorV2/CoreEditorContext';
import {
  ActiveCueGroup,
  CueType,
  StudioCueType,
} from '../components/StudioCues/Cue';
import {
  CueAttributes,
  OnCueClickProps,
} from '../components/EditorV2/extensions/CueExtension';

interface ActiveNode {
  pos?: number;
  attrs?: Partial<CueAttributes>;
  isCue?: boolean;
  isSelection?: boolean;
}

type CuesState = {
  /**
   * This decides what options to display in the right rail of the editor.
   */
  activeCueGroup: ActiveCueGroup;
  /**
   * The node currently selected in the editor. This can be a selection node or a cue node, or null.
   */
  activeNode: ActiveNode | null;
  /**
   * The options for the cue used in the sliders on the right rail that map 1:1 to the cue's attributes.
   */
  cueOptions: CueAttributes;
  /**
   * NOTE: A temporary solution to determine if the editor contains cues. This will be moved to
   *  the editor slice once that is created.
   */
  hasCues: boolean;
};

const defaultCueOptions: CueAttributes = {
  loudness: 0,
  tempo: 0,
  pause: 0,
  pitch: 0,
};

const getCueOptionsFromAttrs = (attrs: Partial<CueAttributes>) =>
  Object.keys(attrs).reduce(
    (acc, key: string) =>
      defaultCueOptions[key] !== undefined
        ? { ...acc, [key]: attrs[key] }
        : acc,
    {}
  );

const initialState: CuesState = {
  activeCueGroup: ActiveCueGroup.inactive,
  activeNode: null,
  cueOptions: defaultCueOptions,
  hasCues: false,
};

interface SelectTokenPayload {
  activeCueGroup: ActiveCueGroup;
  pos: number;
}

type CueClickPayload = OnCueClickProps;

interface UpdateCuePayload {
  items: Array<{
    cueType: CueType;
    value: number;
  }>;
}

/**
 * CuesSlice
 *
 * This slice is responsible for orchestrating the state of cues in the editor between
 * components that add, edit, and remove cues from the editor.
 *
 * The reducer is purely responsible for handling the values inside of the state.
 *
 * Side-effects of the reducer are handled in the thunk actions which call
 * coreEditorRef commands. All processes that do not directly modify the state should be
 * handled in a thunk.
 */
const cuesSlice = createSlice({
  name: 'cues',
  initialState,
  reducers: {
    resetCueOptions: state => {
      return {
        ...state,
        activeCueGroup: ActiveCueGroup.inactive,
        activeNode: null,
        cueOptions: defaultCueOptions,
      };
    },
    selectToken: (state, action: { payload: SelectTokenPayload }) => {
      const { activeCueGroup, pos } = action.payload;
      return {
        ...state,
        activeCueGroup,
        activeNode: { pos, isCue: false, isSelection: true },
        cueOptions: defaultCueOptions,
      };
    },
    setHasCues: (state, action) => {
      return {
        ...state,
        hasCues: action.payload,
      };
    },
    clickCue: (state, action: { payload: CueClickPayload }) => {
      const { activeCueGroup, pos, attrs = {} } = action.payload;
      const { activeNode: prevActiveNode } = state;

      if (prevActiveNode === null) {
        return {
          ...state,
          activeCueGroup,
          activeNode: {
            pos,
            attrs,
            isCue: true,
            isSelection: false,
          },
          cueOptions: {
            ...state.cueOptions,
            ...getCueOptionsFromAttrs(attrs),
          },
        };
      }

      // If the previous node was a selection node and its position is less than the new node's
      // position, account for the selection node's position being removed (2 indices).
      let newPos = pos;

      if (prevActiveNode.isSelection) {
        const wasSelectionIndexBeforeCueIndex =
          isNumber(prevActiveNode.pos) && prevActiveNode.pos < pos;

        newPos = wasSelectionIndexBeforeCueIndex ? pos - 2 : pos;
      }

      return {
        ...state,
        activeCueGroup,
        activeNode: {
          pos: newPos,
          attrs,
          isCue: true,
          isSelection: false,
        },
        cueOptions: {
          ...state.cueOptions,
          ...getCueOptionsFromAttrs(attrs),
        },
      };
    },
    updateCue: (state, action: { payload: UpdateCuePayload }) => {
      const { items } = action.payload;

      // Do nothing if the activeNode isn't set
      if (state.activeNode?.pos === undefined) {
        return state;
      }

      const updatedOptions = {
        ...state.cueOptions,
        ...items.reduce((acc, { cueType, value }) => {
          if (Object.values<string>(CueType).includes(cueType)) {
            return { ...acc, [cueType]: value };
          }
          return acc;
        }, {}),
      } as CueAttributes;

      const areOptionsDifferentThanInitialValues = Object.entries(
        updatedOptions
      )
        .filter(([key]) => Object.values<string>(CueType).includes(key))
        .some(cueOption => {
          const [name, cueValue] = cueOption;
          const key = name as StudioCueType;
          return defaultCueOptions[key] !== cueValue;
        });

      if (!areOptionsDifferentThanInitialValues && state.activeNode?.pos) {
        return {
          ...state,
          activeNode: {
            pos: state.activeNode.pos,
            isCue: false,
            isSelection: true,
          },
          cueOptions: defaultCueOptions,
        };
      }

      return {
        ...state,
        activeNode: {
          ...state.activeNode,
          attrs: updatedOptions,
          isCue: true,
          isSelection: false,
        },
        cueOptions: updatedOptions,
      };
    },
  },
});

/**
 * Removes all cues from the editor and resets the options.
 */
type ClearCuesPayload = { from: number; to: number } | null;
export const clearCues = createAsyncThunk<void, any, { state: AppState }>(
  'cues/clearCues',
  (payload: ClearCuesPayload, { dispatch }) => {
    dispatch(cuesSlice.actions.resetCueOptions());

    const coreEditorRef = getCoreEditorRef();

    coreEditorRef?.commands.removeActiveTokenSelection();

    if (payload) {
      coreEditorRef?.commands.clearCuesInRange(payload);
    } else {
      coreEditorRef?.commands.clearCuesInRange();
    }
  }
);

/**
 * Resets the controls of the editor, reseting the options, removing the selection, and
 * clearing the focused cue.
 */
export const resetControls = createAsyncThunk<void, any, { state: AppState }>(
  'cues/resetControls',
  (_, { dispatch }) => {
    dispatch(cuesSlice.actions.resetCueOptions());

    const coreEditorRef = getCoreEditorRef();

    coreEditorRef?.commands.removeActiveTokenSelection();
    coreEditorRef?.commands.clearFocusedCue();
  }
);

/**
 * Action to dispatch that handles clicking on a cue in the editor.
 * Performing this action will set the new active node in state to the cue that was clicked on,
 *  remove the selection if it exists, and focuses the new active node.
 */
export const clickCue = createAsyncThunk<void, any, { state: AppState }>(
  'cues/clickCue',
  (payload: CueClickPayload, { dispatch, getState }) => {
    dispatch(cuesSlice.actions.clickCue(payload));

    const coreEditorRef = getCoreEditorRef();

    // attempt to remove the selection if it exists
    coreEditorRef?.commands.removeActiveTokenSelection();

    // Set the focused cue given the new activeNode pos from state
    const cuePos = getState()?.cues?.activeNode?.pos;
    if (isNumber(cuePos)) {
      coreEditorRef?.commands.setFocusedCue(cuePos);
    }
  }
);

/**
 * Action to dispatch that handles updating cues via the slider in the cue options sidebar.
 * This action will update the activeNode's attributes in state, and replace the cue with a selection
 *  if the options are the same as the default values or update the relevant attribute on the cue & focus it.
 */
export const updateCue = createAsyncThunk<void, any, { state: AppState }>(
  'cues/updateCue',
  (payload: UpdateCuePayload, { dispatch, getState }) => {
    dispatch(cuesSlice.actions.updateCue(payload));

    const coreEditorRef = getCoreEditorRef();

    // Is the current active node a selection now?
    const { activeNode } = getState().cues;
    if (activeNode?.pos) {
      if (activeNode?.isSelection) {
        // Replace the cue with a selection
        coreEditorRef?.commands.replaceCueWithSelection(activeNode.pos);
      } else {
        // Else, update the cue with the new attributes
        const { pos, attrs } = activeNode;
        coreEditorRef?.commands.updateCue({ pos, attrs });
        coreEditorRef?.commands.setFocusedCue(pos);
      }
    }
  }
);

export const selectToken = createAsyncThunk<void, any, { state: AppState }>(
  'cues/selectToken',
  (payload: SelectTokenPayload, { dispatch }) => {
    dispatch(cuesSlice.actions.selectToken(payload));

    const coreEditorRef = getCoreEditorRef();

    coreEditorRef?.commands.clearFocusedCue();
  }
);

interface AdjustPauseInCuePayload {
  from: number;
  to: number;
}

/**
 * Action to dispatch that attempts to split a cue at the given range (that is assumed to contain a pause token)
 * and then selects the pause token
 */
export const adjustPauseInCue = createAsyncThunk<
  void,
  any,
  { state: AppState }
>('cues/adjustPauseInCue', (payload: AdjustPauseInCuePayload, { dispatch }) => {
  const coreEditorRef = getCoreEditorRef();

  // We need to check 2 positions before the given from because
  // if this is a pause token at the beginning of a cue,
  // it needs 1 index to move to the beginning of the cue
  // and 1 more index to move out of the cue node position
  const posToCheck = payload.from - 2;
  // We add an offset if the cue contains the position to check because
  // the command will add a new cue at the position to check if it doesn't (increasing the index)
  // And the inverse if it does (decreasing the index)
  const offset = coreEditorRef?.helpers?.hasCuesInRange(posToCheck) ? 1 : -1;

  coreEditorRef?.commands.splitCueAtSelection({
    from: payload.from,
    to: payload.to,
  });

  /**
   * @todo: Move selectRange logic directly into the selectToken action
   *
   * Currently, selection is handled at the cuehandler level and is a reaction to
   * the onSelection event on the TokenSelection extension. This worked because at
   * the time, we were only selecting via clicks.
   *
   * Now that we select from different actions, we need to unify the selection logic
   * under the select token action.
   */
  const adjustedFrom = payload.from + offset;
  const adjustedTo = payload.to + offset;
  coreEditorRef?.commands.selectRange({
    from: adjustedFrom,
    to: adjustedTo,
  });

  dispatch(
    selectToken({
      activeCueGroup: ActiveCueGroup.pause,
      pos: adjustedFrom,
    })
  );
});

export const selectCuesState = (state: AppState) => state.cues;

export const { resetCueOptions, setHasCues } = cuesSlice.actions;

export default cuesSlice;
