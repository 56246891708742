import { Request } from 'express';
import { IncomingMessage } from 'http';
import isBrowser from './isBrowser';

/**
 * Simple helper method to pull cookies out of the given context. For server, this is the req object
 * and for client this is found on the window object.
 */
export default function isomorphicCookieJar(
  req?: Request | IncomingMessage | { headers: { [key: string]: any } }
): string {
  if (!isBrowser) {
    return req && req.headers ? req.headers.cookie || '' : '';
  }
  return window ? window.document.cookie : '';
}
