import { makeStyles } from '@material-ui/core';
import React from 'react';
import PrimaryButton, { PrimaryButtonProps } from './PrimaryButton';

interface OverlayButtonProps extends PrimaryButtonProps {}

const useStyles = makeStyles(theme => ({
  overlay: {
    backgroundColor: 'rgba(255, 255, 255, 0.11)',
    color: theme.palette.common.white,
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: 'rgba(255, 255, 255, 0.15)',
      boxShadow: 'none',
    },
    '&.Mui-disabled, &.Mui-disabled:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
      opacity: 0.6,
      color: 'white',
      pointerEvents: 'auto',
      cursor: 'not-allowed',
    },
  },
}));

const OverlayButton: React.FC<OverlayButtonProps> = React.forwardRef(
  ({ children, ...props }, ref) => {
    const classes = useStyles();
    return (
      <PrimaryButton
        className={classes.overlay}
        variant="contained"
        {...props}
        ref={ref}
      >
        {children}
      </PrimaryButton>
    );
  }
);

export default OverlayButton;
