import React from 'react';
import { Button } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import LoadingIcon from '../LoadingIndicators/CircularLoader';

export interface PrimaryButtonProps extends ButtonProps {
  loading?: boolean;
  // used in conjunction with the href prop
  target?: '_blank' | '_top' | '_self' | '_parent';
}

const PrimaryButton: React.FC<PrimaryButtonProps> = React.forwardRef(
  ({ children, loading, disabled, ...props }, ref) => {
    return (
      <Button
        disabled={loading || disabled}
        variant="contained"
        color="primary"
        {...props}
        ref={ref}
      >
        {loading ? <LoadingIcon /> : children}
      </Button>
    );
  }
);

export default PrimaryButton;
