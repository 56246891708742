import { makeStyles, Theme } from '@material-ui/core';
import React, { useState } from 'react';
import SelectDropdown from '../SelectDropdown';
import {
  SelectDropdownProps,
  SelectDropdownListItem,
} from '../SelectDropdown/config';
import { dropdownTemporaryPalette } from '../SelectDropdown/selectDropdownStyles';

export type SelectDropdownV2Props = Omit<SelectDropdownProps, 'listItems'> & {
  listItems: Array<
    SelectDropdownListItem & {
      inactive?: boolean;
    }
  >;
  onOpen?: (open: boolean) => void;
  htmlIconColor?: string;
};

const useStyles = makeStyles<
  Theme,
  {
    inactive: boolean;
    isOpen: boolean;
    findColor: (inactive: boolean, open: boolean) => string;
  }
>(theme => ({
  button: {
    // Rest
    color: ({ inactive, isOpen, findColor }) => findColor(inactive, isOpen),
    borderRadius: 4,
    border: '1px solid rgba(255,255,255,0)',
    padding: theme.spacing(1, 2),
    '&[aria-expanded="true"]': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    '&:hover': {
      // Giving it color since by default, it has no background color
      backgroundColor: dropdownTemporaryPalette.button.restOrFilled,
    },
    '&:focus, &.Mui-focusVisible': {
      backgroundColor: dropdownTemporaryPalette.button.restOrFilled,
      border: `1px solid ${theme.palette.primary.main}`,
      color: dropdownTemporaryPalette.text.initial,
    },
    backgroundColor: ({ isOpen }) =>
      isOpen ? dropdownTemporaryPalette.button.restOrFilled : 'unset',
  },
}));

const SelectDropdownV2 = ({
  listItems,
  onChange,
  onOpen,
  value,
  autoResize = false,
  buttonLabel = undefined,
  ...props
}: SelectDropdownV2Props) => {
  const shouldCurrentItemAppearInactive = Boolean(
    listItems.find(i => i.value === value)?.inactive === false
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOnOpen = (open: boolean) => {
    setIsOpen(open);
    if (onOpen) {
      onOpen(open);
    }
  };

  const findColor = (inactive: boolean, open: boolean) => {
    if (open) return dropdownTemporaryPalette.text.initial;
    if (inactive) return dropdownTemporaryPalette.button.text.filled;
    return dropdownTemporaryPalette.button.text.inactive;
  };

  const classes = useStyles({
    inactive: shouldCurrentItemAppearInactive,
    isOpen,
    findColor,
  });

  return (
    <SelectDropdown
      {...props}
      value={value}
      listItems={listItems}
      buttonLabel={buttonLabel}
      buttonOverrideStyle={classes.button}
      buttonSize="small"
      autoResize={autoResize}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onChange={onChange}
      onOpen={handleOnOpen}
    />
  );
};

export default SelectDropdownV2;
