import getConfig from 'next/config';
import { ApolloLink } from '@apollo/client';
import { Environment } from '../../server/util/env';
import {
  compareSemanticVersions,
  CompareSemanticVersionsResult,
  parseSemanticVersion,
} from '../util/semVerUtils';
import EventTrackingService, {
  EventName,
} from '../services/EventTrackingService';

const { TAG_NAME, SHORT_SHA, _ENV } = getConfig().publicRuntimeConfig;
const environment = (_ENV as Environment | undefined) || 'dev';

export const appVersionHeaderKey = 'X-App-Version';

/**
 * This middleware intercepts all the GraphQL responses from the server side and reads the
 * `X-App-Version` header value and takes action based on different values and environments.
 */
const clientServerVersionLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    // For production and staging environments, this header value is populated as below:
    // Production: The github tag name, which is the release version with semantic format (major.minor.patch)
    // Staging: The short SHA of the commit to main that triggered the staging release
    const context = operation.getContext();

    if (!context?.response) {
      return response;
    }

    const appVersionHeaderValue =
      context.response.headers.get(appVersionHeaderKey);

    if (environment === 'prod') {
      const serverVersion = parseSemanticVersion(appVersionHeaderValue);
      // TAG_NAME format: v1.0.0
      // We already remove the `v` character from the version, when setting the header on the server-side
      const clientVersion = parseSemanticVersion(TAG_NAME.slice(1));

      if (!serverVersion || !clientVersion) {
        return response;
      }

      // Compare response header's semVer with the browser semVer
      const compareResult = compareSemanticVersions(
        serverVersion,
        clientVersion
      );

      if (compareResult === CompareSemanticVersionsResult.MajorChanged) {
        EventTrackingService.track({
          type: EventName.ClientVersionMismatchDetected,
          data: {
            actionTaken: 'forceReload',
            versionChange: CompareSemanticVersionsResult.MajorChanged,
            clientVersion: clientVersion.versionString,
            serverVersion: serverVersion.versionString,
          },
        });
        window.location.reload();
      } else if (compareResult === CompareSemanticVersionsResult.MinorChanged) {
        /**
         * @todo We might want to show a pop-up to the clients recommending them to reload the browser in future.
         */
        EventTrackingService.track({
          type: EventName.ClientVersionMismatchDetected,
          data: {
            actionTaken: 'none',
            versionChange: CompareSemanticVersionsResult.MinorChanged,
            clientVersion: clientVersion.versionString,
            serverVersion: serverVersion.versionString,
          },
        });
      } else if (compareResult === CompareSemanticVersionsResult.PatchChanged) {
        // do nothing
        EventTrackingService.track({
          type: EventName.ClientVersionMismatchDetected,
          data: {
            actionTaken: 'none',
            versionChange: CompareSemanticVersionsResult.PatchChanged,
            clientVersion: clientVersion.versionString,
            serverVersion: serverVersion.versionString,
          },
        });
      }
    }

    if (environment === 'staging') {
      if (
        appVersionHeaderValue &&
        SHORT_SHA &&
        String(appVersionHeaderValue) !== String(SHORT_SHA)
      ) {
        EventTrackingService.track({
          type: EventName.ClientVersionMismatchDetected,
          data: {
            actionTaken: 'forceReload',
          },
        });
        window.location.reload();
      }
    }

    return response;
  });
});

const getClientServerVersionLink = () => clientServerVersionLink;
export default getClientServerVersionLink;
