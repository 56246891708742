/**
 * Pad a number with leading '0's.
 *
 * @param {number} number
 * @param {number} digits Minimum digits in `number`.
 */
export function padDigits(number: number, digits: number): string {
  let string = String(number);
  while (string.length < digits) {
    string = `0${string}`;
  }
  return string;
}

/**
 * Math.floor the number before padding with digits
 *
 * @param number
 * @param digits
 */
export const floorAndPad = (number: number, digits: number): string =>
  padDigits(Math.floor(number), digits);

/**
 * Minute/second string formatting. For example, the input 123 in seconds (or 2 minutes and 3 seconds)
 * would output 02:03.
 *
 * @param {number} valueInSeconds
 */
export const mmSSFormat = (valueInSeconds: number) =>
  `${floorAndPad(valueInSeconds / 60, 2)}:${floorAndPad(
    valueInSeconds % 60,
    2
  )}`;

/**
 * Strips any characters from a string that may be invalid when used as a filename. Replaces
 * whitespace characters with and dash. Invalid characters: / \ : * ? " < >
 *
 * @see http://xahlee.info/mswin/allowed_chars_in_file_names.html
 */
export const filenameFriendlyFormat = (s: string) => {
  return s.replace(/[/\\:*?"<>]/g, '').trim();
};

/**
 * A number formatter that makes large numbers more readable.
 *
 * @see https://stackoverflow.com/a/9462382
 *
 * @example nFormatter(1234, 1) // 1.2k
 *
 * @param num Value to format
 * @param decimals Number of decimals to represent in the formatted output
 */
export const nFormatter = (num: number, decimals: number = 1) => {
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i -= 1) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(decimals).replace(rx, '$1') + si[i].symbol;
};

/**
 * Ensure the first character of a string is uppercase
 *
 * @example uppercaseFirst('test') // Test
 */
export const uppercaseFirst = (str: string) =>
  `${str[0].toUpperCase()}${str.substr(1)}`;

/**
 * Converts an object to a sanitized query string,
 * turns { foo: 'bar', foo2: 'bar2' } into 'foo=bar&foo2=bar2'
 *
 * @param object set of keys and values to turn into a query string
 */
export function parametersToString(object: {
  [key: string]: string | number | boolean;
}) {
  return Object.entries(object)
    .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
    .join('&');
}

/**
 * Extract initials from given first/last name (typically for display purposes)
 *
 * @param {string} firstName
 * @param {string} lastName
 *
 * @example
 * // The following returns the string 'JD'
 * initialsFromFirstAndLastName('Jane', 'Doe');
 */
export const initialsFromFirstAndLastName = (
  firstName: string,
  lastName: string
) => `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;

/**
 * Turns a camel-cased string to a Capitalized sentence.
 *
 * @example
 * camelCaseToCapitalWords('thisIsFun') === 'This Is Fun'
 *
 * @param camel string to un-camel-ize
 * @see https://stackoverflow.com/a/6229124
 */
export const camelCaseToCapitalWords = (camel: string) =>
  camel
    // insert a space between lower & upper
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    // space before last upper in a sequence followed by lower
    .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
    // uppercase the first character
    .replace(/^./, str => str.toUpperCase());
