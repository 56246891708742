import { Slide } from '@material-ui/core';
import {
  createMuiTheme,
  darken,
  responsiveFontSizes,
} from '@material-ui/core/styles';
import {
  CSSProperties,
  CreateCSSProperties,
} from '@material-ui/core/styles/withStyles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { Mixins } from '@material-ui/core/styles/createMixins';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import transitions from '@material-ui/core/styles/transitions';
import palette, { PaletteBridge, paletteBridge, paletteV2 } from './palette';
import typography from './typography';

interface SliderPalette {
  mark: string;
  track: {
    default: {
      backgroundColor: string;
    };
    loudness: {
      backgroundColor: string;
    };
    tempo: {
      backgroundColor: string;
    };
    pitch: {
      backgroundColor: string;
    };
    pause: {
      backgroundColor: string;
    };
  };
  thumb: {
    loudness: {
      backgroundColor: string;
      boxShadowColor: string;
    };
    tempo: {
      backgroundColor: string;
      boxShadowColor: string;
    };
    pitch: {
      backgroundColor: string;
      boxShadowColor: string;
    };
    pause: {
      backgroundColor: string;
      boxShadowColor: string;
    };
    inactive: {
      backgroundColor: string;
    };
  };
}

interface UIPaletteBridge {
  button: {
    primary: {
      surface: {
        default: string;
        hover: string;
      };
      text: {
        default: string;
      };
    };
    secondary: {
      surface: {
        default: string;
        hover: string;
      };
      text: {
        default: string;
      };
      border: {
        default: string;
      };
      hover: {
        boxShadow: {
          default: string;
        };
      };
      focus: {
        border: {
          default: string;
        };
        outline: {
          default: string;
        };
      };
    };
  };
  background: {
    surface: {
      default: string;
    };
  };
  text?: {
    primary: string;
    secondary: string;
    tertiary: string;
  };
  input: {
    primary: {
      text: {
        default: string;
        placeholder: string;
        label: string;
      };
      surface: {
        default: string;
      };
      stroke: {
        default: string;
        focus: string;
        active: string;
      };
    };
    password: {
      icon: {
        default: string;
      };
    };
  };
}

/**
 * Provide custom mixins via declaration merging, for more info:
 * @see https://mui.com/material-ui/customization/theming/#custom-variables
 */
declare module '@material-ui/core/styles/createMixins' {
  interface Mixins {
    primaryGradient: CSSProperties;
    secondaryGradient: CSSProperties;
    secondaryGradientVertical: CSSProperties;
    banner: CSSProperties;
    drawer: CSSProperties;
    studioNavbar: CSSProperties;
    editor: CSSProperties;
    inputBorder: CSSProperties;
    scrollBar: CreateCSSProperties;
    scrollBarHorizontal: CreateCSSProperties;
    palette: PaletteBridge;
    focusOutline: CSSProperties;
    centeredPageContainer: CSSProperties;
  }
}

declare module '@material-ui/core/styles/createPalette' {
  interface TypeBackground {
    paperSecondary: string;
  }

  interface Palette {
    inputs: {
      default: string;
      active: string;
      focus: string;
    };
    accent: {
      main: string;
      primaryOpaque: {
        20: string;
        30: string;
        50: string;
      };
    };
    contained: {
      hover: string;
    };
    slider: SliderPalette;
    ui: UIPaletteBridge;
  }

  interface PaletteOptions {
    inputs: {
      default: string;
      active: string;
      focus: string;
    };
    accent: {
      main: string;
      primaryOpaque: {
        20: string;
        30: string;
        50: string;
      };
    };
    contained: {
      hover: string;
    };
    slider: SliderPalette;
    ui: UIPaletteBridge;
  }
}

export const CONTAINER_GUTTER_WIDTH = 24;
export const RESPONSIVE_EDITOR_MAX_HEIGHT = 700;
export const RESPONSIVE_EDITOR_MIN_HEIGHT = 375;

/**
 * @example
 * Default breakpoints:
 * value         |0px     600px    960px    1280px   1920px
 * key           |xs      sm       md       lg       xl
 * screen width  |--------|--------|--------|--------|-------->
 * range         |   xs   |   sm   |   md   |   lg   |   xl
 */
const breakpoints = createBreakpoints({});

const mixins: Partial<Mixins> = {
  // temporary mixin prior to MUI v5 migration
  palette: paletteBridge,
  primaryGradient: {
    backgroundImage: `linear-gradient(to right, ${paletteBridge.accents.greenGradient.start}, ${paletteBridge.accents.greenGradient.end})`,
  },
  secondaryGradient: {
    backgroundImage: `linear-gradient(270deg, ${palette.accent.main} 0%, #2C3E50 100%)`,
  },
  secondaryGradientVertical: {
    backgroundImage: `linear-gradient(180deg, ${palette.accent.main} 0%, #2C3E50 100%)`,
  },
  inputBorder: {
    borderColor: palette.inputs.default,
    borderWidth: 1,
    borderStyle: 'solid',
    // TODO: we may want to make this property a common mixin that can be applied across varius components
    // similar to `shape` below
    borderRadius: 4,
  },
  // needs to have greater precendence than user-profile-menu
  banner: {
    height: 48,
    zIndex: 1301,
  },
  drawer: {
    width: 270,
  },
  studioNavbar: {
    height: 56,
  },
  editor: {
    minHeight: RESPONSIVE_EDITOR_MIN_HEIGHT,
    maxHeight: RESPONSIVE_EDITOR_MAX_HEIGHT,
  },
  toolbar: {
    minHeight: 80,
    [breakpoints.up('sm')]: {
      minHeight: 80,
    },
  },
  scrollBar: {
    scrollbarWidth: 'thin',
    scrollbarColor: `${palette.grey[500]} transparent`,
    '&::-webkit-scrollbar': {
      width: 6,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: palette.grey[500],
      borderRadius: 6,
    },
    '&::-webkit-scrollbar-corner': {
      display: 'none',
    },
  },
  scrollBarHorizontal: {
    scrollbarWidth: 'thin',
    scrollbarColor: `${palette.grey[500]} transparent`,
    '&::-webkit-scrollbar': {
      height: 6,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: palette.grey[500],
      borderRadius: 6,
    },
  },
  focusOutline: {
    outline: `2px solid ${paletteBridge.greens.lime[100]}`,
    outlineOffset: 4,
    borderRadius: 8,
  },
  // Used to center the page content in the layout with disabled gutters
  centeredPageContainer: {
    display: 'grid',
    [breakpoints.up('lg')]: {
      gridTemplateColumns: 'auto 0 80% 0 auto',
    },
    [breakpoints.down('md')]: {
      gridTemplateColumns: `auto 24px 95% 24px auto`,
    },
  },
};

const wellSaidTheme: ThemeOptions = {
  breakpoints,
  typography,
  palette,
  mixins,
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTypography: {
      variantMapping: {
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
        h6: 'h6',
        subtitle1: 'p',
        subtitle2: 'p',
        body1: 'p',
        body2: 'p',
      },
    },
    MuiGrid: {
      // spacing: 4, // default 16px spacing
    },
    MuiAppBar: {
      color: 'default',
      // elevation: 0,
    },
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiButton: {},
    MuiChip: {
      size: 'small',
    },
    MuiInput: {
      disableUnderline: true,
    },
    MuiInputLabel: {
      shrink: true,
    },
    MuiTabs: {
      textColor: 'primary',
      indicatorColor: 'primary',
    },
    MuiPaper: {
      elevation: 0,
      square: true,
    },
    MuiSwitch: {
      color: 'primary',
    },
    MuiCardHeader: {
      titleTypographyProps: {
        variant: 'body1',
        color: 'textPrimary',
        noWrap: true,
      },
      subheaderTypographyProps: {
        variant: 'body2',
        color: 'textSecondary',
        noWrap: true,
      },
    },
    MuiSnackbar: {
      TransitionComponent: Slide,
    },
    MuiLink: {
      underline: 'always',
    },
    MuiTooltip: {
      enterDelay: 250,
    },
    MuiCheckbox: {
      color: 'default',
      disableRipple: true,
    },
    MuiRadio: {
      color: 'primary',
    },
  },
  overrides: {
    MuiAccordion: {
      root: {
        backgroundColor: paletteV2.grey[1000],
        borderRadius: 4,
        '&.Mui-expanded': {
          margin: 0,
        },
        '&:hover': {
          backgroundColor: paletteV2.grey[900],
        },
        '&:has(.Mui-focused)': {
          opacity: 1,
          outline: `2px solid ${paletteV2.lime[100]}`,
          backgroundColor: paletteV2.grey[900],
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        borderRadius: 4,
        '&.Mui-focused': {
          opacity: 1,
          backgroundColor: paletteV2.grey[900],
        },
      },
      content: {
        margin: `16px 0`,
        '&.Mui-expanded': {
          margin: `16px 0`,
        },
      },
    },
    MuiContainer: {
      root: {
        paddingLeft: CONTAINER_GUTTER_WIDTH,
        paddingRight: CONTAINER_GUTTER_WIDTH,
      },
    },
    MuiAppBar: {
      colorDefault: {
        // backgroundColor: 'transparent',
        backgroundColor: palette.background.default,
      },
    },
    MuiButton: {
      root: {
        fontWeight: 700,
        borderRadius: 4,
        '&.Mui-focusVisible': {
          outline: `2px solid ${paletteV2.lime[100]}`,
          outlineOffset: 2,
          // For buttons w dropdowns, need to bring button forward so outline is displayed
          zIndex: 1,
        },
      },
      contained: {
        borderRadius: 4,
        paddingTop: 12,
        paddingBottom: 12,
        boxShadow: 'none',
        transition: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&.Mui-focusVisible': {
          backgroundColor: palette.primary.dark,
          boxShadow: 'none',
          borderTopRightRadius: 4,
          borderBottomRightRadius: 4,
        },
      },
      containedPrimary: {
        '&:hover, &.Mui-focusVisible': {
          backgroundColor: palette.contained.hover,
        },
        '&.Mui-disabled, &.Mui-disabled:hover': {
          opacity: 0.5,
          backgroundColor: palette.primary.main,
          color: palette.primary.contrastText,
          pointerEvents: 'auto',
          cursor: 'not-allowed',
        },
      },
      containedSecondary: {
        backgroundColor: paletteBridge.buttons.secondary.default,
        '&:hover, &.Mui-focusVisible': {
          backgroundColor: paletteBridge.buttons.secondary.hover,
          boxShadow: 'none',
        },
        '&.Mui-disabled': {
          color: 'rgba(255, 255, 255, 0.5)',
        },
        '&.Mui-disabled, &.Mui-disabled:hover': {
          opacity: 0.5,
          backgroundColor: paletteBridge.buttons.secondary.default,
          pointerEvents: 'auto',
          cursor: 'not-allowed',
        },
      },
      /**
       * Outlined Buttons
       */
      outlined: {
        // Common outlined button styles (limited to sizing, color styles will be defined in the
        // color variants)
        borderRadius: 8,
        fontWeight: 'bold',
        height: 48,
      },
      outlinedPrimary: {
        // Primary outline button styles
        color: palette.common.white,
        border: `2px solid ${palette.primary.main}`,
        '&:hover, &.Mui-focusVisible': {
          backgroundColor: `rgba(0,0,0,0.1)`,
          border: `2px solid ${palette.primary.main}`,
          boxShadow: `0 0 25px -1px ${palette.primary.main}`,
        },
        '&:active': {
          boxShadow: `0 0 18px -1px ${palette.primary.main}`,
          backgroundColor: `rgba(0,0,0,0.25)`,
        },
      },
      outlinedSecondary: {
        // Secondary outline button styles
        color: palette.common.white,
        border: `2px solid rgba(255, 255, 255, 0.3)`,
        '&:hover': {
          backgroundColor: 'transparent',
          border: `2px solid rgba(255, 255, 255, 0.75)`,
        },
        '&.Mui-focusVisible': {
          backgroundColor: 'transparent',
          border: `2px solid rgba(255, 255, 255, 0.75)`,
        },
        '&:active': {
          backgroundColor: 'rgba(255, 255, 255, 0.05)',
          border: `2px solid rgba(255, 255, 255, 0.5)`,
        },
      },
      sizeSmall: {
        borderRadius: 4,
        height: 32,
        lineHeight: 1.5,
        fontSize: `12px`,
      },
      sizeLarge: {
        minWidth: 160,
      },
      outlinedSizeSmall: {
        padding: `0px 16px`,
      },
      /**
       * Text Buttons
       */
      text: {
        fontWeight: 'bold',
        '&:hover, &.Mui-focusVisible': {
          backgroundColor: 'transparent',
        },
      },
      textPrimary: {
        color: 'inherit',
        '&:hover, &.Mui-focusVisible': {
          backgroundColor: 'transparent',
          color: palette.primary.main,
        },
      },
      colorInherit: {},
    },
    MuiCircularProgress: {
      circle: {
        color: palette.common.white,
      },
    },
    MuiIconButton: {
      root: {
        '&.Mui-focusVisible': {
          backgroundColor: palette.action.hover,
        },
      },
    },
    MuiChip: {
      root: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        height: 24,
        minWidth: 32,
        fontSize: 12,
      },
      sizeSmall: {
        height: 16,
        minWidth: 16,
        fontSize: 8,
      },
      label: {
        overflow: 'initial',
      },
      deleteIcon: {
        margin: `0 2px 0 -6px`,
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.8)',
        willChange: 'opacity',
      },
    },
    MuiTabs: {
      root: {
        minHeight: 40,
      },
    },
    MuiTab: {
      root: {
        minHeight: 40,
        padding: `12px 0px`,
        marginRight: 40,
        ...typography.subtitle1,
        fontSize: `0.75rem`,
        textTransform: 'uppercase',
        '&:focus': {
          color: darken(palette.text.primary, 0.15),
        },
        [breakpoints.up('sm')]: {
          minWidth: 192,
        },
      },
      textColorPrimary: {
        '&.Mui-selected': {
          color: palette.text.primary,
        },
      },
      wrapper: {
        alignItems: 'flex-start',
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
        ...typography.h5,
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: palette.grey['700'],
      },
    },
    MuiSlider: {
      root: {
        height: 2,
        color: palette.common.white,
      },
      track: {
        height: 2,
        borderRadius: 2,
      },
      rail: {
        height: 2,
        borderRadius: 2,
        backgroundColor: palette.grey['700'],
      },
      thumb: {
        height: 8,
        width: 8,
        marginTop: -3,
      },
    },
    MuiMenuItem: {
      root: {
        transition: transitions.create('opacity', { duration: 150 }),
        opacity: 0.5,
        '&:hover, &.Mui-focusVisible': {
          opacity: 1,
          backgroundColor: 'initial',
        },
        '&.Mui-disabled': {
          opacity: 0.25,
        },
      },
    },
    MuiSwitch: {
      root: {
        width: 32,
        height: 16,
        padding: 0,
        margin: 0,
        '&:has(.MuiSwitch-switchBase):has(.Mui-focusVisible)': {
          ...mixins.focusOutline,
        },
      },
      switchBase: {
        padding: 0,
        '&$checked': {
          transform: 'translateX(16px)',
        },
        '&$checked .MuiSwitch-thumb': {
          color: palette.primary.main,
        },
      },
      thumb: {
        color: palette.grey[500],
        width: 16,
        height: 16,
      },
      track: {
        borderRadius: 16 / 2,
        backgroundColor: palette.grey['800'],
        opacity: 1,
      },
    },
    MuiCardContent: {
      root: {
        '&:last-child': {
          paddingBottom: 16,
        },
      },
    },
    MuiCardHeader: {
      root: {
        backgroundColor: palette.background.paperSecondary,
      },
      content: {
        // NOTE: this is to make room for the `action` props and prevent text overflowing flex box
        maxWidth: 'calc(100% - 32px)',
        marginRight: 'auto',
      },
      action: {
        marginRight: -16,
      },
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: palette.inputs.default,
          borderWidth: mixins.inputBorder ? mixins.inputBorder.borderWidth : 1,
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: palette.inputs.active,
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            borderColor: palette.inputs.default,
          },
        },
        '&$focused $notchedOutline': {
          borderColor: palette.inputs.active,
          borderWidth: mixins.inputBorder ? mixins.inputBorder.borderWidth : 1,
        },
      },
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: palette.background.paper,
        color: palette.text.primary,
        ...typography.body1,
      },
    },
    MuiFormLabel: {
      root: {
        fontWeight: 'bold',
        // override the default behavior of using `primary.main`. This prevents a color change.
        '&.Mui-focused': {
          color: palette.text.secondary,
        },
      },
    },
    MuiLink: {
      underlineAlways: {
        '&:focus': {
          outline: 'none !important',
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
        fontSize: `0.75rem`,
      },
    },
    MuiCheckbox: {
      root: {
        color: palette.grey[700],
        '&.Mui-checked': {
          color: palette.common.white,
        },
        '&:hover': {
          backgroundColor: 'transparent',
          color: palette.common.white,
        },
        '&.Mui-focusVisible': {
          backgroundColor: 'transparent',
          outline: `2px solid ${paletteBridge.greens.lime[100]}`,
          borderRadius: 8,
          outlineOffset: -4,
        },
      },
    },
    MuiRadio: {
      root: {
        '&:hover, &.Mui-focusVisible': {
          backgroundColor: 'transparent !important',
        },
        '&.Mui-focusVisible': {
          backgroundColor: 'transparent !important',
          outline: `2px solid ${paletteBridge.greens.lime[100]}`,
          outlineOffset: -4,
        },
        '&$checked': {
          '& input+div > svg:first-child': {
            // set the 'outer' ring to white
            fill: palette.text.primary,
          },
        },
      },
    },
    // start: match adornment color with that of input state
    MuiInputBase: {
      root: {
        '&:hover .MuiInputAdornment-root > button': {
          color: palette.inputs.active,
        },
        '&.Mui-focused .MuiInputAdornment-root > button': {
          color: palette.common.white,
        },
      },
    },
    MuiInputAdornment: {
      root: {
        '& > button': {
          color: palette.inputs.default,
        },
        '& > button:hover': {
          color: palette.inputs.active,
        },
        '& > button:focus': {
          color: palette.common.white,
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        color: palette.text.primary,
      },
    },
    MuiTypography: {
      root: {
        /**
         * override the color prop from `error.main` to this new color
         * @todo revisit this after refactor to mui v5, there could
         * be ways to extend the palette easier and there is also
         * additional scoping that we can do on colors in the future
         */
        '&.MuiTypography-colorError': {
          color: paletteBridge.statuses.errorText,
        },
      },
    },
  },
};

export const theme = createMuiTheme(wellSaidTheme);

export type WellSaidTheme = typeof theme;

export default responsiveFontSizes(theme);
