import { Box, ThemeProvider } from '@material-ui/core';
import getConfig from 'next/config';
import React from 'react';
import { theme } from '../../theme';
import { PrimaryButton } from '../Buttons';
import Modal from '../Modal';
import Typography from '../Typography';
import { FallbackRender } from './SentryErrorBoundary';

const { WEB_URI } = getConfig().publicRuntimeConfig;

const Fallback: FallbackRender = ({ error }) => {
  return (
    <ThemeProvider theme={theme}>
      <Modal
        open
        ModalBodyPaperProps={{
          includeMaxWidth: true,
          includeDismissIcon: false,
          onClose: () => {},
        }}
        onClose={() => {}}
      >
        {/* NOTE: this is a specific edge case where the user subscription is invalid */}
        {error.message === 'data.user.subscription is null' ? (
          <Typography isLegacy>
            {`It looks like we are still provisioning your account. \
            If you believe this to be an error, please contact support.`}
          </Typography>
        ) : (
          <Typography isLegacy>
            {`Oops, something went wrong! Please try reloading and/or \
          contacting support for assistance. `}
            <a
              href={`mailto:support@wellsaidlabs.com?subject=WellSaid Studio Error Report&body=${
                error
                  ? `I encountered the following error while using the WellSaid Studio:\
                   %0D%0A[${error.name}] ${error.message}
                   %0D%0A[UserAgent] ${window.navigator.userAgent}
                   `
                  : ''
              }`}
            >{`support@wellsaidlabs.com`}</a>
          </Typography>
        )}
        <Box marginTop={4}>
          <PrimaryButton
            fullWidth
            href={WEB_URI}
          >{`Back to home page`}</PrimaryButton>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default Fallback;
