/**
 * Taken from the working example at the `graphql-ws` github repo.
 *
 * @see https://github.com/enisdenjo/graphql-ws#apollo-client
 */
import {
  ApolloLink,
  FetchResult,
  Observable,
  Operation,
} from '@apollo/client/core';
import { GraphQLError, print } from 'graphql';
import { Client, ClientOptions, CloseCode, createClient } from 'graphql-ws';
import { captureExceptionWithState } from '../util/sentry';
import { handleClientUnauthorizedResponse } from '../services/session';

export default class ApolloWebSocketLink extends ApolloLink {
  private client: Client;

  constructor(options: Omit<ClientOptions, 'on' | 'keepAlive'>) {
    super();
    let activeSocket: WebSocket;
    let timedOut: any;
    this.client = createClient({
      ...options,
      keepAlive: 10_000,
      on: {
        error: this.clientErrorHandler,
        // Websocket ping/pong based on example in docs
        // https://github.com/enisdenjo/graphql-ws/tree/master#ping-from-client
        connected: socket => {
          activeSocket = socket as WebSocket;
        },
        ping: received => {
          if (!received)
            timedOut = setTimeout(() => {
              // Close connection, did not hear pong back from server
              if (activeSocket.readyState === WebSocket.OPEN) {
                activeSocket.close(
                  CloseCode.ConnectionInitialisationTimeout,
                  'Request Timeout'
                );
              }
            }, 5_000);
        },
        pong: received => {
          if (received) clearTimeout(timedOut);
        },
        closed: event => {
          if ((event as { code: CloseCode }).code === CloseCode.Unauthorized) {
            // eslint-disable-next-line no-console
            console.warn(`Socket closed due to Unauthorized event`);
            handleClientUnauthorizedResponse({
              detectionLocation: 'ApolloWebSocketLink::closed',
            });
          }
        },
      },
    });
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable(sink => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: err => {
            if (err instanceof Error) {
              return sink.error(err);
            }
            if (err instanceof CloseEvent) {
              return sink.error(
                // reason will be available on clean closes
                new Error(
                  `Socket closed with event ${err.code} ${err.reason || ''}`
                )
              );
            }
            if (Array.isArray(err)) {
              return sink.error(
                new Error(
                  (err as GraphQLError[])
                    .map(({ message }) => message)
                    .join(', ')
                )
              );
            }
            return sink.error(err);
          },
        }
      );
    });
  }

  /**
   * Error handler for the `graphql-ws` client.
   */
  // eslint-disable-next-line class-methods-use-this
  private clientErrorHandler(error: unknown) {
    // Documentation says err may be an Error instance or Event (string)
    let reportedError: Error;
    if (error instanceof Error) {
      reportedError = error;
    } else if (typeof error === 'string') {
      reportedError = new Error(error);
    } else if (error instanceof Event) {
      // This happens during forceful disconnect and provides no useful information/context,
      // ignoring for now
      return;
    } else {
      reportedError = new Error(`Unkown ws client error`);
    }
    // eslint-disable-next-line no-console
    console.error(reportedError, error);
    // Track via sentry
    captureExceptionWithState(reportedError, {
      type: 'ApolloWebSocketLink',
      value: {
        error: reportedError.message,
      },
    });
  }
}
