import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  split,
  Operation,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { RetryLink } from '@apollo/client/link/retry';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from 'apollo-utilities';
import getConfig from 'next/config';
import fetch from 'isomorphic-unfetch';
import { unionBy } from 'lodash';
import {
  Actor,
  ActorVariant,
  ClipActor,
} from '../../server/graphql/__generated.types';
import { isBrowser } from '../util/isBrowser';
import promiseToObservable from '../util/promiseToObservable';
import ApolloWebSocketLink from './ApolloWebSocketLink';
import relayStylePaginationOverride from '../util/relayStylePaginationOverride';
import ClientServerVersionLink from './ClientServerVersionLink';
import { handleClientUnauthorizedResponse } from '../services/session';
import ApolloWebSocketTerminatingLink from './ApolloWebSocketTerminatingLink';

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

export const RE_AUTHENTICATION_LINK_IGNORE_FLAG = 'ignoreReAuthenticationLink';

/*
  This util is leveraged to share the latest cache for testing purposes
*/
export function createInMemoryCache(initialState?: NormalizedCacheObject) {
  const cache = new InMemoryCache({
    typePolicies: {
      Actor: {
        // At this time there may exist multiple versions of the same Actor, this better defines
        // that uniqueness.
        keyFields: actor => {
          let actorCacheId = `${actor.__typename}:${actor.id}`;

          if (!(actor as Actor).variants[0].version) {
            actorCacheId += ':undefined';
          }

          (actor as Actor).variants.forEach(variant => {
            actorCacheId += `:${variant.id}`;

            if (variant.versions.previous) {
              actorCacheId += `:prev:${variant.versions.previous!.model}`;
            }

            if (variant.versions.latest) {
              actorCacheId += `:latest:${variant.versions.latest!.model}`;
            }

            if (variant.versions.next) {
              actorCacheId += `:next:${variant.versions.next!.model}`;
            }
          });

          return actorCacheId;
        },
      },
      /**
       * If one of the keyFields is an object with fields of its own, you can
       * include those nested keyFields by using a nested array of strings.
       * @see https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-cache-ids
       */
      ActorVariant: {
        keyFields: variant => {
          const v = variant as Partial<ActorVariant>;
          return `${v.__typename}:${v.id}:`.concat(
            // comma-seperated model names
            [
              v.versions?.previous?.model,
              v.versions?.latest?.model,
              v.versions?.next?.model,
            ].join(',')
          );
        },
      },
      AvatarTaggingAttribute: {
        keyFields: ['attribute'],
      },
      ClipActor: {
        keyFields: clipActor => {
          return `${clipActor.__typename}:${clipActor.id}:${
            (clipActor as ClipActor)
              ? `${(clipActor as ClipActor).variant.id}:${
                  (clipActor as ClipActor).variant.version
                }`
              : 'undefined'
          }`;
        },
      },
      SubscriptionPlan: {
        keyFields: [
          'id',
          // The Beta planId is the same for both monthly and yearly plans
          'interval',
          // This value can differ with the same plan when it comes from user vs root queries
          'selectable',
        ],
      },
      Admin: {
        // Admin field is a singleton object, see https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-identifier-generation-by-type
        // for more details.
        keyFields: [],
      },
      Profile: {
        keyFields: ['email'],
      },
      Clip: {
        // According to the documentation - specifying local only read fields shouldn't be required
        // however, not specifying these fields leads to the data result being undefined and throws no errors
        fields: {
          localSrc: {
            read(existing) {
              return existing || null;
            },
          },
          failedWithError: {
            read(existing) {
              return existing || null;
            },
          },
          streamRequest: {
            read(existing) {
              return existing || null;
            },
          },
        },
      },
      Project: {
        fields: {
          clips_V2: relayStylePaginationOverride(),
        },
      },
      User: {
        fields: {
          personalProjects: relayStylePaginationOverride(projectsCacheKeyArgs),
          sharedProjects: relayStylePaginationOverride(projectsCacheKeyArgs),
          teamProjects: relayStylePaginationOverride(projectsCacheKeyArgs),
          contact: {
            merge(existing, incoming) {
              return {
                ...existing,
                ...incoming,
              };
            },
          },
        },
      },
    },
  }).restore(initialState || {});
  return cache;
}

const projectsCacheKeyArgs = ['inputs', ['orderBy', 'search', 'filters']];

const leftToWebSocketLink: (op: Operation) => boolean = ({ query }) => {
  const definition = getMainDefinition(query);
  return (
    definition.kind === 'OperationDefinition' &&
    definition.operation === 'subscription'
  );
};
const leftToHttpLink: (op: Operation) => boolean = ({ query }) => {
  const definition = getMainDefinition(query);
  return !(
    definition.kind === 'OperationDefinition' &&
    definition.operation === 'subscription'
  );
};

/**
 * Create an `ApolloClient` that is used to interface with our graphql interface. This is an
 * isomorphic link that is used on both the client and server for SSR.
 *
 * @param initialState
 * @param cookies
 */
function create(
  initialState: any,
  cookies: string
): ApolloClient<NormalizedCacheObject> {
  const { publicRuntimeConfig } = getConfig();
  const { WEB_URI, WEB_WS_URI, TAG_NAME, SHORT_SHA, _ENV } =
    publicRuntimeConfig;
  const httpLink = isBrowser
    ? createUploadLink({
        uri: `${WEB_URI}/api/graphql`,
        credentials: 'include',
        fetch,
      })
    : new BatchHttpLink({
        uri: `${WEB_URI}/api/graphql`,
        credentials: 'include',
        fetch,
        batchMax: 8, // No more than 8 operations per batch
        batchInterval: 50, // Wait no more than 50ms after first batched operation
      });

  const wsLink = isBrowser
    ? new ApolloWebSocketLink({
        url: `${WEB_WS_URI}/api/graphql`,
        retryAttempts: 8,
      })
    : new ApolloWebSocketTerminatingLink();

  const httpWebSocketSplit = split(leftToWebSocketLink, wsLink, httpLink);

  const authLink = setContext((_: any, { headers }: { headers: object }) => {
    return {
      headers: {
        ...headers,
        cookie: cookies,
      },
    };
  });

  /**
   * Automatically retry requests on network errors. Note that we are using split here to only
   * apply retry logic to our HttpLink as our WebSocketLink has it's own retry logic for
   * establishing websocket connections.
   */
  const retryLink = split(leftToHttpLink, new RetryLink());

  /**
   * This middleware attempts to renew the user's session when a GraphQL response returns an
   * Unauthenticated error response. This is different from the retry logic in `retryLink`, as the
   * Graphql interface can response with a 200 status code but the part of the schema requested
   * requires authentication.
   *
   * @override This re-authentication logic can be bypassed by setting the `ignoreReAuthenticationLink`
   * flag as part of the graphql query context.
   *
   * @see https://www.apollographql.com/docs/link/links/error/#retrying-failed-requests
   */
  const reAuthenticationLink = onError(
    // eslint-disable-next-line consistent-return
    ({ graphQLErrors, operation, forward }) => {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          if (
            err.extensions &&
            err.extensions.code === 'UNAUTHENTICATED' &&
            !operation.getContext()[RE_AUTHENTICATION_LINK_IGNORE_FLAG]
          ) {
            return promiseToObservable(
              new Promise<void>(resolve => {
                handleClientUnauthorizedResponse({
                  detectionLocation: 'ApolloClient::UNAUTHENTICATED',
                });
                // During SSR, we let the unauthorized error bubble up (no way to redirect from here)
                // On the client, intentially do not resolve here to prevent graphql query from
                // completing in the middle of our page load/redirect
                if (!isBrowser) {
                  resolve();
                }
              })
            ).flatMap(() => forward(operation));
          }
        }
      }
    }
  );

  /**
   * Returns an array containing the ApolloLinks used in the client.
   * Note that the order of these links is important.
   */
  const getApolloLinks = (): Array<ApolloLink> => {
    const apolloLinks: Array<ApolloLink> = [];

    apolloLinks.push(reAuthenticationLink);
    if (isBrowser) {
      apolloLinks.push(ClientServerVersionLink());
    }
    apolloLinks.push(retryLink);
    apolloLinks.push(authLink);
    apolloLinks.push(httpWebSocketSplit);

    return apolloLinks;
  };

  // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient
  return new ApolloClient({
    // `name` & `version` are used for metrics reporting: https://www.apollographql.com/docs/studio/metrics/client-awareness/#using-apollo-server-and-apollo-client
    name: isBrowser ? 'studio' : 'studio-ssr',
    version: TAG_NAME || SHORT_SHA,
    connectToDevTools: isBrowser,
    ssrMode: !isBrowser, // Disables forceFetch on the server (so queries are only run once)
    link: ApolloLink.from(getApolloLinks()),
    cache: createInMemoryCache(initialState),
  });
}

/**
 * Initializes an `ApolloClient` instance.
 *
 * For client side rendering, this will only create a single instance of the client and share that
 * in the event that this method is called more than once.
 *
 * For server side rendering this method will create a new instance every call to prevent data
 * sharing across requests (which would be bad!).
 *
 * @param initialState
 * @param appContext
 */
export default function initApollo(
  initialState: Object,
  cookies: string
): ApolloClient<NormalizedCacheObject> {
  if (!isBrowser) {
    return create(initialState, cookies);
  }

  if (!apolloClient) {
    apolloClient = create(initialState, cookies);
  }

  return apolloClient;
}
