import { Button, ButtonProps, darken, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { ReactNode } from 'react';

export interface LabelButtonProps
  extends Omit<ButtonProps, 'variant' | 'children'> {
  variant?: 'button' | 'label';
  filled?: boolean;
  children: ReactNode;
}

const useStyles = makeStyles(theme => {
  const filledColor = '#333333';
  return {
    root: {
      color: theme.palette.common.white,
      backgroundColor: ({ filled }: LabelButtonProps) =>
        filled ? filledColor : 'transparent',
      minWidth: 'initial',
      padding: theme.spacing(theme.typography.pxToRem(3), 0.75),
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      fontWeight: 'inherit',
      textTransform: 'none',
      borderColor: ({ filled }: LabelButtonProps) =>
        filled ? filledColor : theme.palette.inputs.default,
      borderWidth: 1,
      borderStyle: 'solid',
      borderRadius: 0,
      '&:focus:not(:disabled), &:hover:not(:disabled)': {
        borderColor: theme.palette.grey[500],
        backgroundColor: ({ filled }: LabelButtonProps) =>
          filled ? filledColor : 'transparent',
      },
      '&:disabled': {
        color: 'rgba(255, 255, 255, 0.75)',
      },
      '&:active': {
        opacity: 0.75,
      },
    },
    label: {
      '& > span': {
        color: ({ filled }: LabelButtonProps) =>
          filled
            ? darken(theme.palette.common.white, 0.1)
            : theme.palette.common.white,
        userSelect: 'auto',
      },
    },
  };
});

export default function LabelButton({
  className,
  variant = 'button',
  disabled,
  // desconstructing `filled' here to prevent it from being added to the DOM
  filled,
  ...props
}: LabelButtonProps) {
  const classes = useStyles({ ...props, filled });
  const isLabel = variant === 'label';
  const component = isLabel ? 'span' : 'button';
  const role = isLabel ? undefined : 'button';
  return (
    <Button
      {...{
        ...props,
        component,
        disabled: isLabel || disabled,
        role,
        className: clsx(classes.root, { [classes.label]: isLabel }, className),
      }}
    />
  );
}
