import { ApolloLink, NextLink, Operation } from '@apollo/client';
import isBrowser from '../util/isBrowser';

/**
 * An ApolloLink that disables graphql subscription's by immediately terminating.
 */
export default class ApolloWebSocketTerminatingLink extends ApolloLink {
  constructor() {
    super();
    if (isBrowser) {
      console.warn(
        `ApolloWebSocketTerminatingLink is intended to be used during SSR, not in-browser`
      );
    }
  }

  request(operation: Operation, forward?: NextLink) {
    if (operation.operationName === 'subscription') {
      return null; // Return null to prevent the request from being sent
    }
    return forward ? forward(operation) : null;
  }
}
