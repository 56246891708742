import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PhoneticReplacement } from '../../server/graphql/__generated.types';

export interface EnhancedPhoneticReplacement extends PhoneticReplacement {
  isRespelling: boolean;
}

export type ReplacementsState = {
  replacementsEnabled: boolean;
  replacementsLibrary: EnhancedPhoneticReplacement[];
  replacedWordsCount: number;
  replacementsLibraryLoading: boolean;
};

const initialState: ReplacementsState = {
  replacementsEnabled: true,
  replacementsLibrary: [],
  replacedWordsCount: 0,
  replacementsLibraryLoading: true,
};

const replacementsSlice = createSlice({
  name: 'replacements',
  initialState,
  reducers: {
    setLibrary: (state, action) => {
      return {
        ...state,
        replacementsLibrary: action.payload.library,
        replacementsLibraryLoading: false,
      };
    },
    toggleLibrary: (state, action) => {
      return {
        ...state,
        replacementsEnabled: action.payload.enabled,
      };
    },
    setReplacementsState: (
      state,
      action: PayloadAction<Partial<ReplacementsState>>
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setLibrary, toggleLibrary, setReplacementsState } =
  replacementsSlice.actions;

export default replacementsSlice;
