import { makeStyles, Theme } from '@material-ui/core';

/**
 * @todo
 * With introduction of design tokens, this should be removed.
 * Note: this config is leveraged across components outside of this dropdown
 */
export const dropdownTemporaryPalette = {
  button: {
    restOrFilled: 'transparent',
    openDisabledOrFocused: 'transparent',
    hover: 'rgba(255, 255, 255, 0.08)',
    text: {
      inactive: 'rgba(255, 255, 255, 0.6)',
      filled: 'rgba(255, 255, 255, 0.6)',
    },
  },
  menu: {
    rest: 'rgba(255, 255, 255, 0.11)',
    items: {
      hover: 'rgba(255, 255, 255, 0.08)',
      selected: 'rgba(51, 51, 51, 1)',
    },
  },
  text: {
    initial: 'rgba(255, 255, 255)',
    default: 'rgba(255, 255, 255, 0.38)',
    filled: 'rgba(255, 255, 255, 0.87)',
  },
};

export const useStyles = makeStyles<
  Theme,
  { transformText?: boolean },
  | 'button_isOpen'
  | 'button_isClosed'
  | 'buttonIcon_isOpen'
  | 'buttonIcon_isClosed'
  | 'icon'
  | 'menuBottomSpacer'
  | 'menuItem'
  | 'menuItemWrapper'
  | 'menuPaper'
  | 'disabled'
>(theme => ({
  button_isOpen: props => ({
    width: '100%',
    maxWidth: '100%',
    backgroundColor: dropdownTemporaryPalette.button.openDisabledOrFocused,
    borderRadius: 4,
    border: `1px solid ${theme.palette.inputs.default}`,
    padding: theme.spacing(0.75, 1, 0.75, 1),
    '&:hover': {
      border: `1px solid ${theme.palette.common.white}`,
      color: theme.palette.common.white,
    },
    '&[aria-expanded="true"]': {
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.common.white,
    },
    '&:focus, &.Mui-focusVisible': {
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: dropdownTemporaryPalette.button.openDisabledOrFocused,
      color: theme.palette.common.white,
      outline: 'none',
    },
    color: dropdownTemporaryPalette.button.text.inactive,

    textTransform: props.transformText ? 'capitalize' : 'none',
    '&.MuiButton-text': {
      fontWeight: 'inherit',
      fontSize: '12px',
      justifyContent: 'unset',
    },
    '&.Mui-disabled, &.Mui-disabled:hover': {
      // TODO: global button dropdown input changes - determine final values
      // opacity: 0.5,
      pointerEvents: 'auto',
      cursor: 'not-allowed',
      border: `1px solid ${theme.palette.inputs.default}`,
      color: theme.palette.inputs.default,
      '&:hover, &:focus, &.Mui-focusVisible': {
        backgroundColor: dropdownTemporaryPalette.button.openDisabledOrFocused,
      },
    },
  }),
  button_isClosed: props => ({
    width: '100%',
    maxWidth: '100%',
    backgroundColor: dropdownTemporaryPalette.button.restOrFilled,
    borderRadius: 4,
    border: `1px solid ${theme.palette.inputs.default}`,
    padding: theme.spacing(0.75, 1, 0.75, 1),
    '&:hover': {
      border: `1px solid ${theme.palette.common.white}`,
      color: theme.palette.common.white,
    },
    '&[aria-expanded="true"]': {
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.common.white,
    },
    '&:focus, &.Mui-focusVisible': {
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: dropdownTemporaryPalette.button.openDisabledOrFocused,
      color: theme.palette.common.white,
      outline: 'none',
    },
    color: dropdownTemporaryPalette.button.text.filled,
    textTransform: props && props.transformText ? 'capitalize' : 'none',
    '&.MuiButton-text': {
      fontWeight: 'inherit',
      fontSize: '12px',
      justifyContent: 'unset',
    },
    '&.Mui-disabled, &.Mui-disabled:hover': {
      // TODO: global button dropdown input changes - determine final values
      // opacity: 0.5,
      pointerEvents: 'auto',
      cursor: 'not-allowed',
      border: `1px solid ${theme.palette.inputs.default}`,
      color: theme.palette.inputs.default,
      '&:hover, &:focus, &.Mui-focusVisible': {
        backgroundColor: dropdownTemporaryPalette.button.openDisabledOrFocused,
      },
    },
  }),
  buttonIcon_isOpen: {
    color: dropdownTemporaryPalette.button.text.inactive,

    '&:hover': {
      color: theme.palette.inputs.active,
    },
    '&[aria-expanded="true"]': {
      color: theme.palette.inputs.focus,
    },
    '&:focus, &.Mui-focusVisible': {
      color: dropdownTemporaryPalette.button.text.inactive,
    },
    // no defined disabled state yet
  },
  buttonIcon_isClosed: {
    color: dropdownTemporaryPalette.button.text.inactive,

    '&:hover': {
      color: theme.palette.inputs.active,
    },
    '&[aria-expanded="true"]': {
      color: theme.palette.inputs.focus,
    },
    '&:focus, &.Mui-focusVisible': {
      color: dropdownTemporaryPalette.button.text.inactive,
    },
    // no defined disabled state yet
  },
  icon: {
    paddingLeft: theme.spacing(0.5),
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  menuBottomSpacer: {
    height: theme.spacing(1),
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderRadius: 4,
    padding: theme.spacing(0.875, 1.25),
    maxWidth: '100%',
    boxSizing: 'border-box',
  },
  menuItemWrapper: {
    fontSize: theme.spacing(1.5),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 1, 0, 1),
    opacity: 1,
    '&.Mui-focusVisible': {
      '& > div': {
        backgroundColor: `${dropdownTemporaryPalette.menu.items.hover}`,
      },
    },
    '& > div': {
      '&:hover, &.Mui-focusVisible': {
        backgroundColor: `${dropdownTemporaryPalette.menu.items.hover}`,
      },
    },
    '&.Mui-selected': {
      backgroundColor: 'unset',
      '&:hover': {
        backgroundColor: 'unset',
      },
      '& > div': {
        backgroundColor: `${dropdownTemporaryPalette.menu.items.selected}`,
        '&:hover': {
          backgroundColor: `${dropdownTemporaryPalette.menu.items.hover}`,
        },
      },
    },
  },
  disabled: {
    cursor: 'auto',
    '&.Mui-focusVisible': {
      '& > div': {
        backgroundColor: `${dropdownTemporaryPalette.button.openDisabledOrFocused}`,
      },
    },
    '& > div': {
      '&:hover, &.Mui-focusVisible': {
        backgroundColor: `${dropdownTemporaryPalette.button.openDisabledOrFocused}`,
      },
    },
  },
  menuPaper: {
    borderRadius: 4,
    // Note: this could potentially provide an offset so the dropdown is right aligned with the icon
    // marginLeft: ({ icon }) => (icon ? -14 : 0),
  },
}));

export default useStyles;
