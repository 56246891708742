import { ButtonProps, MenuProps } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { IconProps } from '../Icon';
import useSelectDropdownStyles from './selectDropdownStyles';

export const DEFAULT_TEXT = 'Choose something';

export type SelectDropdownListItem = {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  displayChildren?: boolean;
  disabledYetInteractive?: boolean;
  divider?: boolean;
  height?: number | string;
  key: string;
  value: string;
};

export type SelectDropdownProps = Pick<
  MenuProps,
  'anchorOrigin' | 'transformOrigin'
> & {
  autoResize?: boolean;
  /**
   * Higher priority class to be used in conjuction with existing styles.
   * If buttonOverrideStyle is defined, this will not be applied.
   */
  buttonClassName?: string;
  /**
   * Class to override existing styles.
   * If buttonClassName is defined along with this prop, buttonClassName will not be applied.
   */
  buttonOverrideStyle?: string;
  /**
   * Size to apply to the Material UI Button
   * https://mui.com/material-ui/api/button/
   */
  buttonSize?: ButtonProps['size'];
  /**
   * Aria label for the button
   */
  buttonLabel?: string;
  color?: 'secondary';
  disabled?: boolean;
  /**
   * NOTE: May degrade/slow down performance if used in large lists!
   */
  disablePortal?: boolean;
  /**
   * If an icon is present - leverage this vs default text dropdown
   */
  icon?: IconProps['type'];
  /**
   * html color for icon
   */
  htmlIconColor?: string;
  iconSize?: IconProps['fontSize'];
  id: string;
  listItems: Array<SelectDropdownListItem>;
  menuPaperWidth?: number | string;
  onChange: (selected: string) => void;
  onOpen?: (isOpen: boolean) => void;
  openMenuText?: string;
  staticButtonText?: string;
  value?: string;
  classes: ReturnType<typeof useSelectDropdownStyles>;
  widthElement?: React.RefObject<HTMLDivElement>;
};
