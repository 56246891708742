const paragraphRegex = /.+?(?:\r?\n\r?\n|\r?\n(?!\r?\n)|$)/gs;

/**
 * Takes a string and return an array of string chunked out by when its content
 * is separated by 1 or more new lines.
 * @see https://regex101.com/r/k4HAJT/1 for more on this RegEx
 *
 * @param text Text to chunk
 * @returns {Array<string>} array paragraphs
 */
export default function paragraphSplitter(text: string): Array<string> {
  const matches = text.match(paragraphRegex);
  if (!matches) return [];
  return matches.map(match => match.trim()).filter(match => match.length);
}
