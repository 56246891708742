import {
  addListener as _addListener,
  createListenerMiddleware,
} from '@reduxjs/toolkit';
import type { TypedAddListener } from '@reduxjs/toolkit';
import { AppState, AppDispatch } from './index';

const listenerMiddleware = createListenerMiddleware();

export const addListener = _addListener as TypedAddListener<
  AppState,
  AppDispatch
>;

export default listenerMiddleware;
