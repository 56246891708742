import {
  Typography as TypographyBase,
  TypographyProps,
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import useStyles from './typographyTheme';
import typographyPalette, { AllColorVariants } from './typographyPalette';

type LibraryVariants = TypographyProps['variant'];
type InternalVariants = 'lg' | 'md' | 'sm' | 'xs';
type V3Variants = 'p1' | 'p2' | 'p3' | 'p4' | 'p5';

type TypographyColor = TypographyProps['color'];

/**
 * @description color by purpose
 */
type SemanticColors = 'error';

/**
 * @description combine MuiTypography props with additional variants
 * and modifiers
 */
export type LegacyAugmentedTypographyProps = Omit<
  TypographyProps,
  'variant' | 'color'
> & {
  isLegacy?: true;
  version?: 'v1' | 'v2' | 'v3';
  /**
   * Extends library variants with our variants
   */
  variant?: LibraryVariants;
  /**
   * @todo variants to remap to or replace (`textSecondary`, `body1`, `body2` etc)
   * @see https://www.figma.com/file/TWgty8gcTip6JWbfKJigFb/WL-Styles-v1.0-Breve?type=design&node-id=4006-81878&mode=design&t=v5DDX5kl2CsBUkeZ-0
   */
  color?: TypographyColor;
  /**
   * replaces the outer element with a given component
   *
   * IE `<Typography component={"a"}...` becomes `<a>children</a>`
   */
  component?: React.ElementType;
};

export type FutureAugmentedTypographyProps = Omit<
  TypographyProps,
  'variant' | 'color'
> & {
  isLegacy?: false;
  version?: 'v1' | 'v2' | 'v3';
  /**
   * Extends library variants with our variants
   */
  variant: LibraryVariants | InternalVariants | V3Variants;
  /**
   * Whether or not the element should be bold
   * @note we may extend this to support light/normal in the future
   */
  bold?: boolean;
  /**
   * underlines text
   */
  underlined?: boolean;
  /**
   * uppercases text
   */
  uppercased?: boolean;
  /**
   * determines font
   */
  serif?: boolean;
  /**
   * @todo variants to remap to or replace (`textSecondary`, `body1`, `body2` etc)
   * @see https://www.figma.com/file/TWgty8gcTip6JWbfKJigFb/WL-Styles-v1.0-Breve?type=design&node-id=4006-81878&mode=design&t=v5DDX5kl2CsBUkeZ-0
   */
  color?: AllColorVariants | SemanticColors;
  /**
   * replaces the outer element with a given component
   *
   * IE `<Typography component={"a"}...` becomes `<a>children</a>`
   */
  component?: React.ElementType;
};

export type BaseTypography =
  | LegacyAugmentedTypographyProps
  | FutureAugmentedTypographyProps;

/**
 * @note this would ultimately live within theme config
 * and this is an intermediate step
 */
export enum BaseTypographicalColor {
  light = '#000',
  dark = '#fff',
}

/**
 * @description a helper method that will determine which
 * base class and modifier classes to apply
 */
const deriveClassList = (
  serif: FutureAugmentedTypographyProps['serif'],
  variant: FutureAugmentedTypographyProps['variant'],
  underlined: FutureAugmentedTypographyProps['underlined'],
  uppercased: FutureAugmentedTypographyProps['uppercased'],
  bold: FutureAugmentedTypographyProps['bold'],
  classes: ReturnType<typeof useStyles>,
  isV3: boolean
) => {
  let baseVariantOrNull =
    !serif && variant && variant !== 'inherit'
      ? classes[variant as keyof typeof classes]
      : null;
  const validSerifOrNull =
    serif && ['h1', 'h2', 'h3', 'h4'].includes(variant!)
      ? classes[`${variant}Serif` as keyof typeof classes]
      : null;

  if (
    baseVariantOrNull &&
    isV3 &&
    ['h1', 'h2', 'h3', 'h4', 'h5'].includes(variant!)
  ) {
    // @ts-ignore
    baseVariantOrNull = classes[`${variant as keyof typeof classes}V3`];
  }

  const boldOrNull = bold ? classes.bold : null;
  const uppercasedOrNull = uppercased ? classes.uppercase : null;
  const underlinedOrNull = underlined ? classes.underline : null;
  const classList = [
    baseVariantOrNull,
    validSerifOrNull,
    boldOrNull,
    underlinedOrNull,
    uppercasedOrNull,
  ].filter(Boolean);
  return classList;
};

/**
 * @description helper which will compose a style
 * if it exists and apply a root theme color if
 * applicable
 * @note this theme could be applied at the
 * ThemeProvider level in the future within
 * `getDesignTokens`
 */
const deriveStyle = (
  color: FutureAugmentedTypographyProps['color'],
  style: FutureAugmentedTypographyProps['style']
): FutureAugmentedTypographyProps['style'] => {
  let colorFromMap;
  if (color) {
    const [base, variant] = color.split('-');
    if (!base || !variant) {
      // eslint-disable-next-line no-console
      console.error('[Typography]: color not supported');
      return undefined;
    }
    // assuming the keys are correct - if they are not color will be undefined
    colorFromMap = typographyPalette[base as never][variant as never];
  }
  if (style) {
    return { ...style, color: style.color || colorFromMap || 'inherit' };
  }
  if (colorFromMap) {
    return { color: colorFromMap };
  }
  return undefined;
};

/**
 * @description this component in the future will handle all typographical
 * changes in the studio - it's a simple wrapper around the Core Mui Typography
 * @todo add `component` support
 */
const Typography = (props: BaseTypography) => {
  const { version } = props;
  const classes = useStyles();
  const { children, isLegacy, ...restProps } = props;
  if (isLegacy)
    return (
      <TypographyBase {...(restProps as LegacyAugmentedTypographyProps)}>
        {children}
      </TypographyBase>
    );
  const {
    bold,
    className,
    color,
    serif,
    style,
    underlined,
    uppercased,
    variant,
    version: omitVersion,
    ...rest
  } = props as FutureAugmentedTypographyProps;
  const classList = deriveClassList(
    serif,
    variant,
    underlined,
    uppercased,
    bold,
    classes,
    version === 'v3'
  );

  // note: potentially we could have a class per color vs a derived style with color
  const semanticMap: Record<SemanticColors, AllColorVariants> = {
    error: 'orange-400',
  };
  const remapColor = semanticMap[color as SemanticColors] || color;
  const composedStyle = deriveStyle(remapColor, style);

  // remap variant to internal library variant tag
  const variantTagMap: Record<InternalVariants | V3Variants, LibraryVariants> =
    {
      lg: 'h1',
      md: 'h2',
      sm: 'h4',
      xs: 'h5',
      p1: 'body1',
      p2: 'body2',
      p3: 'subtitle1',
      p4: 'subtitle2',
      p5: 'caption',
    };
  const tag = variantTagMap[variant as InternalVariants] || variant;

  return (
    <TypographyBase
      {...rest}
      // type cast because tag would never be the new addition
      variant={tag as LibraryVariants}
      className={clsx(...classList, className)}
      style={composedStyle}
    >
      {children}
    </TypographyBase>
  );
};

export default Typography;
