import isBrowser from '../../util/isBrowser';
import type { Script } from '../../store/EditorSlice';

/**
 * ProjectEditorStorage is a wrapper around sessionStorage that provides
 * methods for getting and setting the editor value for a project.
 *
 * TODO: We should implement functionality in this class
 *  for storing the prosemirror JSON using similar functions
 */
export default class ProjectEditorStorage {
  private _projectId: string | undefined;

  /**
   * Creates a new ProjectEditorStorage instance.
   *
   * @param projectId The id of the project to store the editor value for
   */
  constructor(projectId: string | undefined) {
    this._projectId = projectId;
  }

  /**
   * Sets the editor value for a project.
   */
  public static setEditorValueForProject(
    projectId: string | undefined,
    editorValue: string
  ): void {
    const storage = new ProjectEditorStorage(projectId);
    storage.setEditorValue(editorValue);
  }

  /**
   * Gets the editor value for a project.
   */
  public static getEditorValueForProject(projectId: string): string {
    const storage = new ProjectEditorStorage(projectId);
    return storage.getEditorValue();
  }

  /**
   * Gets the editor script for a project.
   */
  public static getEditorScriptForProject(projectId: string): string {
    const storage = new ProjectEditorStorage(projectId);
    return storage.getEditorScript();
  }

  /**
   * Returns the projectId or empty string if not set.
   */
  get projectId(): string {
    return this._projectId || '';
  }

  /**
   * Returns the sessionStorage object if in a browser environment.
   */
  get storage(): Storage | undefined {
    if (!isBrowser) {
      return undefined;
    }
    return sessionStorage;
  }

  public getEditorScript(): string {
    return this.storage?.getItem(this.getEditorScriptKey()) ?? '';
  }

  public getEditorValue(): string {
    return this.storage?.getItem(this.getEditorValueKey()) ?? '';
  }

  public setEditorValue(editorValue: string): void {
    this.storage?.setItem(this.getEditorValueKey(), editorValue);
  }

  public getEditorValueKey(): string {
    return `editor_value_${this.projectId}`;
  }

  public getEditorScriptKey(): string {
    return `editor_script_${this.projectId}`;
  }

  public setEditorScriptValues(script: Script): void {
    this.storage?.setItem(this.getEditorScriptKey(), JSON.stringify(script));
  }
}
