import { FieldPolicy } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';

/**
    Unfortunately, we can't rely on relayStylePagination() internal merge function
    this is due to the fact it expects before or after cursor references
    at the root level - and our arguments are always located inside of
    `inputs`
    @see https://github.com/apollographql/apollo-client/issues/8524

    // internal merge implementation that is being overwritten for reference
    // it's worth noting the implementation returns a startCursor, hasPreviousPage as well for PageInfo
    // but is not neededly currently for our use case (currently supporting forward pagination only)
    web/node_modules/@apollo/client/utilities/policies/pagination.js
*/
const relayStylePaginationOverride = (
  ...inputs: Parameters<typeof relayStylePagination>
): FieldPolicy => {
  const relayStylePaginationArgs = relayStylePagination(...inputs);
  return {
    ...relayStylePaginationArgs,
    merge(existing, incoming, { args, ...rest }) {
      if (typeof relayStylePaginationArgs.merge !== 'function') {
        throw new Error('Missing merge definition');
      }
      return relayStylePaginationArgs.merge(existing, incoming, {
        args: {
          ...args,
          after: args?.inputs.cursor,
        },
        ...rest,
      });
    },
  };
};
export default relayStylePaginationOverride;
