import { Snackbar } from '@material-ui/core';
import copy from 'copy-to-clipboard';
import React, { useCallback, useEffect, useState } from 'react';
import IconButton, { IconButtonProps } from '../IconButton/IconButton';
import Icon from '../Icon';

interface TextCopyButtonProps
  extends Omit<IconButtonProps, 'children' | 'onClick'> {
  copyText: string;
  // `copy-to-clipboard` does not export the Options arg
  copyOptions?: Parameters<typeof copy>[1];
  onCopyMessage?: string;
}

const COPIED_DISPLAY_TIMEOUT = 2000;

/**
 * @description The `TextCopyButton` copies the text prop to the clipboard while
 * giving the user feedback that the text was copied.
 */
const TextCopyButton: React.FC<TextCopyButtonProps> = React.forwardRef(
  ({ copyText, copyOptions, onCopyMessage, ...props }, ref) => {
    const [copied, setCopied] = useState<boolean>(false);

    const handleCopy = useCallback(() => {
      if (copy(copyText, copyOptions)) {
        setCopied(true);
      }
    }, [copyText, copyOptions]);

    const handleAlertClose = useCallback(() => {
      setCopied(false);
    }, []);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
      if (copied) {
        const timeoutId = setTimeout(() => {
          setCopied(false);
        }, COPIED_DISPLAY_TIMEOUT);
        return () => {
          clearTimeout(timeoutId);
        };
      }
    }, [copied]);

    return (
      <>
        <IconButton
          {...props}
          disabled={copied}
          color="default"
          onClick={handleCopy}
          ref={ref}
        >
          <Icon type="copy" />
        </IconButton>
        <Snackbar
          open={copied}
          autoHideDuration={COPIED_DISPLAY_TIMEOUT}
          onClose={handleAlertClose}
          message={onCopyMessage ?? 'Link copied!'}
        />
      </>
    );
  }
);

export default TextCopyButton;
