import { AppContextType } from 'next/dist/shared/lib/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { getDataFromTree } from '@apollo/client/react/ssr';
import initApollo from './initApollo';
import { isBrowser } from '../util/isBrowser';
import isomorphicCookieJar from '../util/isomorphicCookieJar';
import ApolloOperationTrackingLink from './ApolloOperationTrackingLink';
import { SSRResponseStats } from '../../server/metrics.d';

interface WithApolloPropType {
  apolloState: Object;
  ssrReqHeaders?: {
    [key: string]: any;
  };
}

export default function withApollo(App: any) {
  return class WithApollo extends React.Component<WithApolloPropType> {
    // eslint-disable-next-line react/static-property-placement
    static displayName = `WithApollo(${App.displayName})`;

    // eslint-disable-next-line react/static-property-placement
    static propTypes = {
      // eslint-disable-next-line react/forbid-prop-types
      apolloState: PropTypes.object.isRequired,
    };

    static async getInitialProps(
      appContext: AppContextType
    ): Promise<WithApolloPropType | {}> {
      const {
        Component,
        router,
        ctx: { req, res, query, pathname, asPath },
      } = appContext;

      const apollo = initApollo({}, isomorphicCookieJar(req));
      let apolloState = {};

      const extendedAppContext: AppContextType = {
        ...appContext,
        ctx: {
          ...appContext.ctx,
          apolloClient: apollo,
        },
      };

      let appProps = {};
      if (App.getInitialProps) {
        appProps = await App.getInitialProps(extendedAppContext);
      }

      if (res && res.finished) {
        // When redirecting, the response is finished.
        // No point in continuing to render
        return {};
      }

      if (!isBrowser) {
        // Run all graphql queries in the component tree
        // and extract the resulting data
        try {
          // We temporarily add a link to track operations that occur during this SSR.
          const originalLink = apollo.link;
          const operationsLink = new ApolloOperationTrackingLink();
          apollo.setLink(operationsLink.concat(originalLink));
          const start = Date.now();
          // Run all GraphQL queries
          await getDataFromTree(
            <App
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...appProps}
              Component={Component}
              router={router}
              apolloClient={apollo}
            />,
            // Manually pass along Next.js related context since we are forcing App to render
            // outside of Next.js wrapper/context.
            {
              router: { query, pathname, asPath },
            }
          );
          // Extract query data from the Apollo's store
          apolloState = apollo.cache.extract();
          apollo.setLink(originalLink);
          const ssrDuration = Date.now() - start;
          const stats: SSRResponseStats = {
            nextjs_pathname: pathname,
            apollo_ssrDuration: ssrDuration,
            apollo_ssrOperations: operationsLink.operations,
            apollo_ssrCacheSize: Buffer.byteLength(JSON.stringify(apolloState)),
          };
          // @ts-ignore Trouble extending NextJS ServerResponse and express Response types/interfaces
          res!.WSL_stats = stats;
        } catch (error) {
          // Prevent Apollo Client GraphQL errors from crashing SSR.
          // Handle them in components via the data.error prop:
          // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
          // @ts-ignore Assuming server side req access
          if (req && req.log) {
            // @ts-ignore
            req.log.error('Error while running `getDataFromTree`', error);
          } else {
            console.error('Error while running `getDataFromTree`', error);
          }
        }
      }

      return {
        ...appProps,
        apolloState,
        ssrReqHeaders: req ? req.headers : undefined,
      };
    }

    apolloClient: ApolloClient<NormalizedCacheObject>;

    constructor(props: Readonly<WithApolloPropType>) {
      super(props);
      // `getDataFromTree` renders the component first, the client is passed off as a property.
      // After that rendering is done using Next's normal rendering pipeline
      this.apolloClient = initApollo(
        props.apolloState,
        isomorphicCookieJar(
          props.ssrReqHeaders ? { headers: props.ssrReqHeaders } : undefined
        )
      );
    }

    render() {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <App {...this.props} apolloClient={this.apolloClient} />;
    }
  };
}
