import React, { useEffect, useRef, useState } from 'react';
import { DEFAULT_TEXT, SelectDropdownProps } from './config';
import SelectDropdownChild from './SelectDropdownChild';
import SelectDropdownMenu from './SelectDropdownMenu';

const SelectDropdown = ({
  anchorOrigin,
  autoResize = true,
  buttonClassName,
  buttonLabel = undefined,
  buttonOverrideStyle,
  buttonSize = 'medium',
  color,
  disabled,
  disablePortal,
  icon,
  iconSize,
  id,
  listItems,
  menuPaperWidth,
  onChange,
  onOpen,
  openMenuText,
  staticButtonText,
  transformOrigin,
  value,
  classes,
  widthElement = undefined,
  htmlIconColor,
}: SelectDropdownProps) => {
  const menuRef = useRef() as React.MutableRefObject<HTMLButtonElement>;
  const [menuWidth, setWidth] = useState<number>();

  let clientWidth = widthElement?.current?.offsetWidth;
  if (menuRef?.current) {
    clientWidth = menuRef?.current?.offsetWidth;
  }

  /**
   * Menu state
   */
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null
  );

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleOptionClick = (optionValue: string) => {
    if (optionValue !== value) onChange(optionValue);
    setMenuAnchorEl(null);
  };

  // Dropdown menu is absolute position, so can't inherit width from parent; setting this manually
  useEffect(() => {
    if (autoResize) {
      setWidth(clientWidth);
    }
  }, [autoResize, menuRef, clientWidth, widthElement]);

  useEffect(() => {
    if (onOpen) onOpen(Boolean(menuAnchorEl));
  }, [onOpen, menuAnchorEl]);

  const renderButtonText = () => {
    if (staticButtonText) return <>{staticButtonText}</>;

    if (menuAnchorEl) {
      return <>{openMenuText ?? DEFAULT_TEXT}</>;
    }

    const selectedListItem = listItems.find(item => item.value === value);
    if (selectedListItem?.displayChildren) {
      return selectedListItem?.children;
    }

    return <>{value ?? DEFAULT_TEXT}</>;
  };
  return (
    <>
      <SelectDropdownMenu
        buttonLabel={buttonLabel}
        buttonClassName={buttonClassName}
        buttonOverrideStyle={buttonOverrideStyle}
        buttonSize={buttonSize}
        classes={classes}
        disabled={Boolean(disabled)}
        icon={icon}
        iconSize={iconSize}
        id={id}
        listItems={listItems}
        menuAnchorEl={menuAnchorEl}
        menuRef={menuRef}
        onClick={handleMenuOpen}
        value={value}
        isOpen
        htmlIconColor={htmlIconColor}
      >
        {renderButtonText()}
      </SelectDropdownMenu>
      <SelectDropdownChild
        anchorOrigin={
          anchorOrigin ?? {
            vertical: 'bottom',
            horizontal: 'left',
          }
        }
        classes={classes}
        disabled={disabled}
        disablePortal={disablePortal}
        icon={icon}
        id={id}
        listItems={listItems}
        menuAnchorEl={menuAnchorEl}
        onClose={handleMenuClose}
        onOptionClick={handleOptionClick}
        transformOrigin={
          transformOrigin ?? {
            vertical: 'top',
            horizontal: 'left',
          }
        }
        value={value}
        menuPaperWidth={menuPaperWidth}
        menuWidth={menuWidth}
      />
    </>
  );
};

export default SelectDropdown;
