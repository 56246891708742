/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
import { ThemeProvider } from '@material-ui/core';
import App from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import NProgress from 'nprogress';
import React from 'react';
import '../all.scss';
import AppCookieProvider from '../lib/AppCookieProvider';
import ErrorBoundary from '../components/ErrorBoundary';
import Feedback from '../components/Feedback';
import { AppProvider } from '../context/AppContext';
import { PromptsProvider } from '../context/prompts/PromptsContext';
import withApollo from '../lib/withApollo';
import * as gtag from '../services/gtag';
import theme from '../theme';
import { init as initSentry } from '../util/sentry';
import ApolloProvider from '../lib/ApolloProvider';
import ReduxProvider from '../store/Provider';

initSentry();

let loadingStatusPerRoute = null;
Router.events.on('routeChangeStart', url => {
  loadingStatusPerRoute = url;
  setTimeout(() => {
    if (loadingStatusPerRoute === url) {
      NProgress.start();
    }
  }, 200);
});
Router.events.on('routeChangeComplete', url => {
  if (loadingStatusPerRoute === url) {
    if (NProgress.status > 0) NProgress.done();
    loadingStatusPerRoute = null;
  }
});
Router.events.on('routeChangeError', (err, url) => {
  if (loadingStatusPerRoute === url) {
    if (NProgress.status > 0) NProgress.done();
    loadingStatusPerRoute = null;
  }
});

Router.events.on('routeChangeComplete', url => {
  gtag.pageview(url);
});

class MyApp extends App {
  // NOTE: this static method will effectively disable Automatic Static Optimization
  // for the entire app. This is here as a result of static optimization not
  // injecting the env variables. See https://nextjs.org/docs#custom-app for more info.
  // Our withApollo HOC also disables static optimization via getInitialProps as well.
  static async getInitialProps(appContext) {
    const appProps = await App.getInitialProps(appContext);
    return { ...appProps };
  }

  constructor(props) {
    super(props);
    this.onFeedback = this.onFeedback.bind(this);
  }

  componentDidMount() {
    // [material-ui] Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }

  onFeedback(...args) {
    return this.feedbackRef.handleFeedback(...args);
  }

  render() {
    const { router, Component, pageProps, apolloClient } = this.props;

    return (
      <>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        </Head>
        <ErrorBoundary>
          <ApolloProvider client={apolloClient}>
            <ReduxProvider>
              <ThemeProvider theme={theme}>
                <AppProvider onFeedback={this.onFeedback}>
                  <AppCookieProvider>
                    <PromptsProvider>
                      {Component.Layout ? (
                        <Component.Layout router={router}>
                          <Component {...pageProps} />
                        </Component.Layout>
                      ) : (
                        <Component {...pageProps} />
                      )}
                    </PromptsProvider>
                  </AppCookieProvider>
                </AppProvider>
                <Feedback
                  ref={ref => {
                    this.feedbackRef = ref;
                  }}
                />
              </ThemeProvider>
            </ReduxProvider>
          </ApolloProvider>
        </ErrorBoundary>
      </>
    );
  }
}

MyApp.displayName = 'App';

export default withApollo(MyApp);
